import { ITableFilter, ModalContainerService, NotificationComponent } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import ColumnType from "src/app/models/columnType";
import columnFilters from "./table-filter";
import tableHeader from "./table-header";
import TableHeaderCol from "./table-header-col";
import RowItem from "./table-row";

@Component({
  selector: "select-user",
  templateUrl: "./selectUser.component.html",
  styleUrls: ["./selectUser.component.css"]
})
export default class SelectUserComponent implements OnInit {
  @Output() close = new EventEmitter<RowItem[]>();

  @Input() tableData: RowItem[] = [];
  tableRows: RowItem[];

  @Input() title: string;

  @Input() activeSave: boolean;

  @Input() notificationTitle = "Notification";

  @Input() notificationBody = "Are you sure you would like to save the changes?";

  @Output() activeSaveChange = new EventEmitter<boolean>();

  public tableHeader: TableHeaderCol[] = tableHeader;

  public columnFilters: ITableFilter[] = columnFilters;

  columnType = ColumnType;

  ngOnInit(): void {
    console.log(this.tableData);
  }

  constructor(private activemodal: ModalContainerService) {}

  submit(): void {
    if (!this.activeSave) {
      return;
    }

    const modalInstance = this.activemodal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = this.notificationTitle;
    modalInstance.instance.body = this.notificationBody;
    modalInstance.result.then(async (result) => {
      this.emitTable(result);
    });
  }

  emitTable(shouldEmit: boolean | number): void {
    if (shouldEmit) {
      this.close.emit(this.tableData.filter(row => row.checked));
    }
  }

  cancel(): void {
    this.close.emit([]);
  }

  checkBoxChange(): void {
    const count = this.tableData.filter((row) => {
      return row.checked;
    }).length;

    this.activeSaveChange.emit(count > 0);
  }

  test(i: number, item: RowItem) {
    return item.id;
  }
}
