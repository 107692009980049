<div class="maincontainer" *ngIf="submittal">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [allowMovingFiles]="true"
        (moveResolutionForm)="addFileToAttachment($event,submittalFileType.CommentResolutionForm)"
        (moveResolutionForms)="addFilesToAttachments($event,submittalFileType.CommentResolutionForm)"
        (moveComment)="addFileToAttachment($event, submittalFileType.MarkedUpComments)"
        (moveComments)="addFilesToAttachments($event,submittalFileType.MarkedUpComments)"
      ></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <aecom-button
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>
              
              <aecom-button
                data-cy="sendBack"
                innerClass="btn lg outline blue"
                (click)="sendBack()"
              >
                Send Back
              </aecom-button>

              <aecom-button
                data-cy="requestComments"
                *ngIf="requireBluebeam && skipCompile"
                innerClass="btn lg outline blue"
                (click)="requestComments()"
              >
                Request Comments
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="submittal">
        <div class="col-12" *ngIf="submittal.submittal_disposition">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              [title]="'Processing'"
              class="matchToTab mb24"
            >
              <ng-container *ngIf="showSRReview">
                <aecom-form-item
                  data-cy="toSrManager"
                  [label]="'1.Does this require review by Sr. PM?'"
                  [required]="true"
                  columnSpan="1"
                  [info]="!showReview ? 'SPM review is always required for Mandatory Approval and Deemed Approval type submittals' : null" 
                  theme="light"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      [theme]="'light'"
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_disposition.ToSrManager
                          ? 'blue selectedBtn'
                          : 'bg-grey-e unSelectedBtn'
                      }}"
                      [disabled]="!showReview ? true : null"
                      (click)="setSendToSrManager(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      [theme]="'light'"
                      innerClass="btn solid btn-tab {{
                        !submittal.submittal_disposition.ToSrManager
                          ? 'blue selectedBtn'
                          : 'bg-grey-e unSelectedBtn'
                      }}"
                      [disabled]="!showReview ? true : null"
                      (click)="setSendToSrManager(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <ng-container
                  *ngIf="submittal.submittal_disposition.ToSrManager; else itemPlaceHolder"
                >
                  <aecom-form-item
                    id="srManager"
                    [label]="'Sr. PM'"
                    [required]="true"
                    columnSpan="1"
                  >
                    <aecom-dropdown
                      data-cy="srManager"
                      [theme]="'light'"
                      placeholder="Select"
                      [data]="srManagerList"
                      (ngValueChange)="setSrManager($event)"
                      [selectedId]="submittal.SrManagerId"
                      [validate]="attemptToIssue && !submittal.SrManagerId"
                    ></aecom-dropdown>
                  </aecom-form-item>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!submittal.submittal_disposition.ToSrManager">
                <aecom-form-item
                  data-cy="toDDC"
                  [label]="(!showSRReview ? '1' : '2')+'. Does this require a final review by DDC?'"
                  [required]="true"
                  columnSpan="1"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        submittal.submittal_disposition.ToDDC
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setSendToDDC(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        !submittal.submittal_disposition.ToDDC
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setSendToDDC(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <ng-container
                  *ngIf="submittal.submittal_disposition.ToDDC; else itemPlaceHolder"
                >
                  <aecom-form-item
                    id="ddcManager"
                    [label]="'DDC Manager'"
                    [required]="true"
                    columnSpan="1"
                  >
                    <aecom-dropdown
                      data-cy="ddcManager"
                      [theme]="'light'"
                      placeholder="Select"
                      [data]="ddcManagersList"
                      (ngValueChange)="setDDCManager($event)"
                      [selectedId]="submittal.DDCManagerId"
                      [validate]="attemptToIssue && !submittal.DDCManagerId"
                    ></aecom-dropdown>
                  </aecom-form-item>
                </ng-container>
              </ng-container>
              
            </aecom-form-group>
            <aecom-form-group
              columnSpan="6"
              columns="2"
              [title]="IsFinal() ? 'Final Response':'Draft Response'"
              class="matchToTab mb24"
            >
              <aecom-form-item
                data-cy="disposition"
                columnSpan="6"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 1
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(1)"
                  >
                    Revise & Re-Submit
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 2
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(2)"
                  >
                    {{ showReview ? "Reviewed" : "Approved" }}
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 3
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(3)"
                  >
                    {{
                      showReview
                        ? "Reviewed w/ Comments"
                        : "Approved w/ Comments"
                    }}
                  </aecom-button>
                  <aecom-button *ngIf="showDenied"
                    innerClass="btn solid btn-tab {{
                      submittal.submittal_disposition.Disposition === 4
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setDisposition(4)"
                  >
                    Denied
                  </aecom-button>
                </div>
                <div class="error-message" *ngIf="!submittal.submittal_disposition.Disposition && attemptToIssue">
                  Required Field
                </div>
              </aecom-form-item>
              <aecom-form-item
                [label]="'Note for DB'"
                [property]="
                  getNoteLength() + '/' + noteMaxLength + ' characters'
                "
                columnSpan="6"
                class="formItemNoMargin"
                [required]="true"
                data-cy="noteForDB"
              >
                <aecom-textbox
                  data-cy="note"
                  rows="6"
                  [theme]="'light'"
                  [notEmpty]="true"
                  [maxlength]="noteMaxLength"
                  [isMultiLine]="true"
                  [(ngValue)]="submittal.submittal_disposition.Note"
                  (ngValueChange)="setNote($event)"
                  [disabled]="dispositionForm !== null"
                  [validate]="
                    (attemptToIssue && !submittal.submittal_disposition.Note?.length)
                    || getNoteLength() > noteMaxLength || baseFunctionService.isInputInvalid(submittal.submittal_disposition.Note)
                  "
                ></aecom-textbox>
              </aecom-form-item>
              <aecom-form-item
                *ngIf="!IsFinal()"
                columnSpan="6"
                class="formItemNoMargin"
              >
                <aecom-checkbox
                  data-cy="acknowledge"
                  [ngValue]="acknowledge"
                  (ngValueChange)="setAcknowledge($event)"
                  [validate]="acknowledge !== true && attemptToIssue"
                  class="gray777"
                >
                Please ensure that you have reviewed the draft response and verified the accuracy of the attached files. Once you proceed, any further edits can only be made by contacting the individuals responsible for the next step in the workflow.<span style="color: red;">*</span>
                </aecom-checkbox>
                <div
                  class="error-message"
                  *ngIf="acknowledge !== true && attemptToIssue"
                >
                  Required Field
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <!-- <div
              columnSpan="6"
              style="grid-column: 'span 6'; margin-bottom: 20px"
            >
              <aecom-checkbox
                data-cy="acknowledge"
                [ngValue]="acknowledge"
                (ngValueChange)="setAcknowledge($event)"
                [validate]="acknowledge !== true && attemptToIssue"
                class="gray777"
              >
                I acknowledge that I am submiting my final disposition and I
                will no longer be able to edit.
              </aecom-checkbox>

              <div
                class="error-message"
                *ngIf="acknowledge !== true && attemptToIssue"
              >
                Required Field
              </div>
            </div> -->
            <!-- <aecom-form-item
                columnSpan="6"
                columns="1"
                style="margin-bottom: 20px"
              >
              <div class="d-flex">
                <aecom-button
                innerClass="btn lg solid blue"
                (click)="generateDispositionForm()"
              >
              Generate Disposition Form
              </aecom-button>
              </div>
            </aecom-form-item> -->

            
            <aecom-form-group
              columnSpan="3"
              columns="1"
              title="Comment Resolution Form"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="resolutionForm"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="commentResolutionForm"
                  [theme]="'light'"
                  (ngValueChange)="
                    importFile($event, submittalFileType.CommentResolutionForm)
                  "
                  (ngDeleteChange)="
                    deleteFile($event, submittalFileType.CommentResolutionForm)
                  "
                  [required]="submittal.submittal_disposition.Disposition && submittal.submittal_disposition.Disposition !== 2 && submittal.submittal_disposition.Disposition !== 4"
                ></aecom-upload>
                <div
                  class="error-message"
                  *ngIf="
                    submittal.submittal_disposition.Disposition !== 2 &&
                    submittal.submittal_disposition.Disposition !== 4 &&
                    commentResolutionForm?.length === 0 &&
                    attemptToIssue
                  "
                >
                  Required Field
                </div>
                <div
                  class="error-message"
                  *ngIf="!commentResolutionFormNameValid"
                >
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
              columnSpan="3"
              columns="1"
              title="Additional Files"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="attachment"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="markedUpComments"
                  [theme]="'light'"
                  (ngValueChange)="
                    importFile($event, submittalFileType.MarkedUpComments)
                  "
                  (ngDeleteChange)="
                    deleteFile($event, submittalFileType.MarkedUpComments)
                  "
                ></aecom-upload>
                <div class="error-message" *ngIf="!markedUpCommentsNameValid">
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
              columnSpan="3"
              columns="1"
              *ngIf="IsFinal() && submittal.submittal_disposition.Disposition && submittal.submittal_disposition.Note && submittal.submittal_disposition.Note.trim().length > 0"
              title="Cover Sheet & STAMP Signed"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="coverSheet"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="coverSheet"
                  [theme]="'light'"
                  [onlyGenerated]="true"
                  (generateFile)="generateDispositionForm()"
                  (ngDeleteChange)="deleteFile($event, submittalFileType.CoverSheetStampSigned)"
                  [required]="true"
                ></aecom-upload>
                
                <div
                  class="error-message"
                  *ngIf="coverSheet?.length === 0 && attemptToIssue"
                >
                  Required Field
                </div>
                <div class="error-message" *ngIf="!coverSheetNameValid">
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>

          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showDispositionForm" class="up-contentcontainer">
  <app-report [submittal]="entity" [disposition]="dispositionForm" [attachments]="attachments" (closeWindow)="closeDispositionForm($event)"></app-report>
</div>
<ng-template #itemPlaceHolder>
  <aecom-form-item columnSpan="1">
  </aecom-form-item>
</ng-template>