import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalSpecificationService from "@services/local-specification.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import LocalSubmittalPageService from "@services/local-submittalPage.service";
import LocalSubmittalTypeService from "@services/local-submittalType.service";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import {
  SubmittalService,
} from "../api-generated";
import {
  ISubmittalListPrepare,
  UserRole,
} from "../models/ISubmittalListPrepare";
import SubmittalStatus from "../models/submittalStatus";
import GetStatusService from "../services/get-status.service";
import LoadingService from "../services/loading.service";
import RouteParams from "../shared/route-params";
import LocalContractRequirementService from "@services/local-contractRequirement.service";
import LocalPhaseService from "@services/local-phase.service";
import SubmittalRole from "@models/submittalRole";
import { AuthService } from "@bbj/components";

export class RowItem {
  public id: string;
  public priority: number;
  public submittalNumber: string;
  public submittalTitle: string;
  public specificationTitle: string;
  public specificationId: string;
  public doctypetitle: string;
  public doctypeCode: string;
  public docTypeId: string;
  public scheduledsubmittaldate: string;
  public actualsubmittal: string;
  public scheduledreview: string;
  public actualreviewed: string;
  public ballInCourt: string;
  public ballInCourtInfo: ballInCourtInfo | string;
  public ballInCourtUser: any;
  public status: SubmittalStatus;
  public response: string;
  public isHidden: boolean;
  public viewOnly: boolean;
  public btnText: string;
  public btnClass: string;
  public btnUrl: string;
}

interface ballInCourtInfo {
  name: string;
  email: string;
}

@Injectable({
  providedIn: "root",
})
export default class ListResolver {
  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public submittalService: SubmittalService,
    public getStatusService: GetStatusService,
    public localContractUserService: LocalContractUserService,
    public localSpecificationService: LocalSpecificationService,
    public localSubmittalTypeService: LocalSubmittalTypeService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
    public localSubmittalPageService: LocalSubmittalPageService,
    public localSubmittalItemService: LocalSubmittalItemService,
    public localContractRequirementService: LocalContractRequirementService,
    public localPhaseService: LocalPhaseService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<ISubmittalListPrepare> {
    this.loadingService.start();

    const currentUser = this.authService.getUserId();
    const contractId = route.parent.params[RouteParams.ContractId];
    const role = this.localContractUserService.getUserById(currentUser).RolesId;

    const userRole: UserRole = {
      statusList: this.getStatusService.getStatusFilters(role, false),
      subtitle: this.getUserTitle(role),
      role: role,
    };
    console.log("Test: App role", userRole.role);

    return forkJoin([
      of(this.localSpecificationService.getItems()),
      (this.localSubmittalItemService.firstLoad ? of(this.localSubmittalItemService.getItems()) : this.submittalService.getAllSubItems(contractId)),
      of(userRole),
      of(this.localSubmittalDocumentTypeService.getItems()),
      of(this.localSubmittalTypeService.getItems()),
      of(this.localSubmittalPageService.getItems()),
      of(this.localContractRequirementService.getItems()),
      of(this.localPhaseService.getItems()),
    ]).pipe(
      map((res) => {
        this.localSubmittalItemService.firstLoad = false;
        return {
          specificationList: res[0],
          submittalList: res[1],
          contractUserRoles: res[2],
          documentTypeList: res[3],
          submittalTypeList: res[4],
          pageList: res[5],
          contractRequirementList: res[6],
          phaseList: res[7],
        };
      }),
      first(),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate([contractId, "error", error.status ? error.status : 500]);
        return of(null);
      }),
    );
  }

  getUserTitle(userRole: string): string {
    switch (userRole) {
      case SubmittalRole.DBAdmin:
        return "Design Builder | User";
      case SubmittalRole.Doc_Control:
        return "DOC CONTROL";
      case SubmittalRole.Manager:
        return "Project Manager";
      case SubmittalRole.SR_Manager:
        return "Sr. Project Manager";
      case SubmittalRole.Coordinator:
        return "Coordinator";
      case SubmittalRole.DDC_Manager:
        return "DDC Manager";
      default:
        return "User";
    }
  }

  isRespondStep(status: string): boolean {
    return (
      status === SubmittalStatus.Reviewed ||
      status === SubmittalStatus.Reviewed_With_Comments ||
      status === SubmittalStatus.Accepted ||
      status === SubmittalStatus.Accepted_With_Comments
    );
  }
}
