import {
  FileModel,
} from "@aecom/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalReopenDetail from "@models/submittalReopenDetail";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import BaseFunctionService from "@services/baseFunction.service";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import { reviewSubmittalTypeId } from "@shared/staticValue";
import { canRevise, getFilesByType, getVersionString, isDB, prepareMutiLineText } from "@shared/utils";
import _ from "lodash";
import {
  IPSubDocumentDownload,
  IPSubItem,
} from "src/app/api-generated";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export default class ViewComponent implements OnInit {
  contractId: string;

  entity: IPSubItem;

  currentUser: string;

  coverSheet: FileModel[] = [];

  markUpComments: FileModel[] = [];

  commentResolutionForm: FileModel[] = [];

  defaultTab = "details";

  docs: IPSubDocumentDownload[] = [];

  showResult = false;

  note: string;

  title = "Submittal Response";

  noteLabel = "Note";

  coverSheetLable = "Cover Sheet & Stamp Signed";

  isDbAdmin = false;

  isDocControl = false;

  isReject = false;

  showRevise = false;

  showRecallButton = true;

  isInformation = false;

  showReassignBtn = false;

  showReopenBtn = false;

  reopenItem: SubmittalReopenDetail;

  showReopenItem = false;

  showReopenAcknowledge = false;

  acknowledge = false;

  reason: string | null = null;

  reasonMaxLength = 2000;

  attemptToIssue = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public localContractUserService: LocalContractUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const { submittal, documents, currentUserId, role, isDoc }: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;

    this.currentUser = currentUserId;
    this.contractId = submittal.ContractId;
    if (submittal) {
      this.entity = submittal;
      this.docs = documents;
      const isConfidential = this.entity.submittal_create?.length > 0 ? this.entity.submittal_create[0].IsConfidentail ?? false : false;
      this.isDbAdmin = isDB(isConfidential, this.entity.SubmittedBy, currentUserId, role);
      this.isDocControl = isDoc;
      const recallStatus: string[] = [
        SubmittalStatus.Draft,
        SubmittalStatus.Submitted,
        SubmittalStatus.In_Review,
        SubmittalStatus.Bluebeam,
        SubmittalStatus.Bluebeam_Compiling,
        SubmittalStatus.Bluebeam_Request,
        SubmittalStatus.Bluebeam_Uploaded,
        SubmittalStatus.Compiling,
        SubmittalStatus.Distribution,
        SubmittalStatus.Review_Period,
      ];
      this.showRecallButton = !this.entity.IsRecalled && recallStatus.findIndex((s)=>{return s===this.entity.Status})>=0;
      const reassignStatus: string[] = [
        SubmittalStatus.Bluebeam_Compiling, 
        SubmittalStatus.Bluebeam_Disposition, 
        SubmittalStatus.Bluebeam_Request, 
        SubmittalStatus.Bluebeam_Uploaded, 
        SubmittalStatus.Compiling, 
        SubmittalStatus.Compiling_Disposition, 
        SubmittalStatus.DDC_Disposition, 
        SubmittalStatus.Disposition, 
        SubmittalStatus.Distribution, 
        SubmittalStatus.In_Review, 
        SubmittalStatus.PM_Review, 
        SubmittalStatus.Review_Period, 
        SubmittalStatus.SPM_Review
      ]

      this.showReassignBtn = 
        (role === SubmittalRole.Doc_Control || role === SubmittalRole.Coordinator) && 
        reassignStatus.findIndex((s)=>{return s===this.entity.Status})>=0
      console.log("role", role);

      this.showReopenBtn = this.isEndStep(this.entity.Status) && this.hasPermissionToReopen();
      if (
        (this.entity.Status === SubmittalStatus.QAQC_Fail) &&
        this.entity.submittal_reject_detail &&
        this.entity.submittal_reject_detail.length > 0
      ) {
        const lastReject = this.entity.submittal_reject_detail.sort(
          (a, b) =>
            new Date(b.DateUpdated).getTime() -
            new Date(a.DateUpdated).getTime(),
        )[0];

        this.showResult = true;
        this.isReject = true;
        this.note = prepareMutiLineText(lastReject.Note);
        this.noteLabel = "Remarks";
        this.title = "Reviewed By AHJV";
        this.coverSheetLable = "QA/QC Check Failure Form";

        if (documents && documents.length > 0) {
          const documnetsForThis = documents.filter((item) => {
            return item.ReferenceId === lastReject.Guid;
          });

          if (documnetsForThis.length > 0) {
            this.coverSheet = getFilesByType(
              documnetsForThis,
              SubmittalFileType.CoverSheetStampSigned,
            );
          } else {
            const docsByStatusAndVersion = documents.filter((item) => {
              return (
                (item.Status === SubmittalStatus.Submitted ||
                  item.Status === SubmittalStatus.In_Review) &&
                item.Submittal_version ===
                  getVersionString(this.entity.SubmittalNumber)
              );
            });

            this.coverSheet = getFilesByType(
              docsByStatusAndVersion,
              SubmittalFileType.CoverSheetStampSigned,
            );
          }
        }
      } else if (
        ([SubmittalStatus.In_Revision,
          SubmittalStatus.Resubmitted,
          SubmittalStatus.Revise_Resubmit,
          SubmittalStatus.Reviewed,
          SubmittalStatus.Reviewed_With_Comments,
          SubmittalStatus.Accepted,
          SubmittalStatus.Accepted_With_Comments].findIndex((s)=>{return s===this.entity.Status})>-1) &&
        this.entity.submittal_disposition &&
        this.entity.submittal_disposition.length > 0
      ) {
        this.showRevise = canRevise(this.entity.Status) &&
          this.entity.BallInCourt === SubmittalRole.DBAdmin;

        const lastCompile = this.entity.submittal_disposition.sort(
          (a, b) =>
            new Date(b.DateUpdated).getTime() -
            new Date(a.DateUpdated).getTime(),
        )[0];

        if (lastCompile.IsDraft === false) {
          this.showResult = true;
          this.note = prepareMutiLineText(lastCompile.Note);

          if (this.isDbAdmin) {
            this.title =
              this.entity.Status === SubmittalStatus.In_Revision ||
              this.entity.Status === SubmittalStatus.Resubmitted ||
              this.entity.Status === SubmittalStatus.Revise_Resubmit ||
              this.entity.submittal_create[0].SubmittalTypeId === reviewSubmittalTypeId
                ? "Reviewed By AHJV"
                : "Response";
          }
        }

        if (documents && documents.length > 0) {
          const documnetsForThis = documents.filter((item) => {
            return item.ReferenceId === lastCompile.Guid;
          });

          this.coverSheet = getFilesByType(
            documnetsForThis,
            SubmittalFileType.CoverSheetStampSigned,
          );

          this.commentResolutionForm = getFilesByType(
            documnetsForThis,
            SubmittalFileType.CommentResolutionForm,
          );

          this.markUpComments = getFilesByType(
            documnetsForThis,
            SubmittalFileType.MarkedUpComments,
          );
        }
      } else if(this.entity.Status === SubmittalStatus.Denied) {
        let refId: string | null = null;
        if(this.entity.submittal_disposition && this.entity.submittal_disposition.length > 0)
        {
          const lastCompile = this.entity.submittal_disposition.sort(
            (a, b) =>
              new Date(b.DateUpdated).getTime() -
              new Date(a.DateUpdated).getTime(),
          )[0];
  
          if (lastCompile.IsDraft === false) {
            this.showResult = true;
            this.note = prepareMutiLineText(lastCompile.Note);
            this.title = "Reviewed By DDC/AHJV"
            refId = lastCompile.Guid
          }
        }
        else if(this.entity.submittal_reject_detail && this.entity.submittal_reject_detail.length > 0)
        {
          const lastReject = this.entity.submittal_reject_detail.sort(
            (a, b) =>
              new Date(b.DateUpdated).getTime() -
              new Date(a.DateUpdated).getTime(),
          )[0];
  
          this.showResult = true;
          this.note = prepareMutiLineText(lastReject.Note);
          this.noteLabel = "Remarks";
          this.title = "Reviewed By DDC/AHJV";
          this.coverSheetLable = "QA/QC Check Failure Form";
          refId = lastReject.Guid;
        }
        if (documents && documents.length > 0) {
          const documnetsForThis = documents.filter((item) => {
            return item.ReferenceId === refId;
          });

          this.coverSheet = getFilesByType(
            documnetsForThis,
            SubmittalFileType.CoverSheetStampSigned,
          );

          this.commentResolutionForm = getFilesByType(
            documnetsForThis,
            SubmittalFileType.CommentResolutionForm,
          );

          this.markUpComments = getFilesByType(
            documnetsForThis,
            SubmittalFileType.MarkedUpComments,
          );
        }
      } else if (
        (this.entity.IsRecalled ||
          this.entity.Status === SubmittalStatus.Canceled) &&
        this.entity.submittal_recall &&
        this.entity.submittal_recall.length > 0
      ) {
        this.showResult = true;
        this.title = "Recall Reason";

        const lastRecall = this.entity.submittal_recall.sort(
          (a, b) =>
            new Date(b.DateUpdated).getTime() -
            new Date(a.DateUpdated).getTime(),
        )[0];

        this.noteLabel = "Note from Design Builder";
        this.note = prepareMutiLineText(lastRecall.Notes ?? "");
      } 
      if(this.isDbAdmin && this.entity.Status !== SubmittalStatus.Voided &&
        this.entity.submittal_reopen && 
        this.entity.submittal_reopen.length>0)
      {
        const latestReopen = this.entity.submittal_reopen.sort((a,b)=>(b.DateRequest?new Date(b.DateRequest).getTime():0)-(a.DateRequest?new Date(a.DateRequest).getTime():0))[0];
        if(!this.isEndStep(this.entity.Status))
        {
          this.showReopenItem = true;
          this.reopenItem = new SubmittalReopenDetail(latestReopen);
        } else {
          this.showReopenAcknowledge = latestReopen.ResponseBy === null;
        }
      }

      if (this.isDbAdmin && this.entity.ShowRecallRejected) {
        this.baseFunctionService.dbReadReject();
      }

      if (
        this.isDbAdmin &&
        this.entity.BallInCourt === SubmittalRole.DBAdmin &&
        this.entity.Viewed !== true &&
        (this.entity.Status === SubmittalStatus.Reviewed ||
          this.entity.Status === SubmittalStatus.Reviewed_With_Comments ||
          this.entity.Status === SubmittalStatus.Accepted ||
          this.entity.Status === SubmittalStatus.Accepted_With_Comments)
      ) {
        this.baseFunctionService.dbViewSubmittal();
      }
    }

    this.loadingService.stop();
  }

  back(): void {
    this.baseFunctionService.back();
  }

  recallSubmittal(): void {
    this.baseFunctionService.recallSubmittal();
  }

  reviseSubmittal(): void {
    this.baseFunctionService.reviseSubmittal();
  }

  reassign(): void {
    this.baseFunctionService.reassign();
  }

  reopen(): void {
    //this.baseFunctionService.subReopen({ChangeType:"1,2,3",Reason:"33",Significance:1});
    //this.baseFunctionService.subReopen({ChangeType:"1,2,3,6",OtherChangeType:"588",Reason:"33",Significance:2});
    this.baseFunctionService.subReopen();
  }

  reopenApproval(): void {
    this.baseFunctionService.subReopenAcknowledge();
  }

  isEndStep(status: string): boolean {
    const finalArr: string[] = [
      SubmittalStatus.Denied,
      SubmittalStatus.Accepted,
      SubmittalStatus.Accepted_With_Comments,
      SubmittalStatus.Reviewed,
      SubmittalStatus.Reviewed_With_Comments,
      SubmittalStatus.Revise_Resubmit
    ];
    return finalArr.includes(status);
  }

  hasPermissionToReopen(): boolean {
    if(this.isDocControl)
    {
      return true;
    }
    return this.currentUser === this.entity.ReviewedBy;
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
  }

  getStatusBeforeReopen(): string | undefined {
    const flows = this.entity.submittal_flow.filter((f)=>{return f.Status!==SubmittalStatus.Request_to_Reopen && f.Status!==SubmittalStatus.DB_Acknowledgment}).sort((a,b)=>(b.DateReceived?new Date(b.DateReceived).getTime():0)-(a.DateReceived?new Date(a.DateReceived).getTime():0));
    if(flows.length > 0)
    {
      return flows[0].Status;
    }
    return undefined;
  }

  getReasonLength(): number {
    return this.reason
      ? this.reason.length
      : 0;
  }
}
