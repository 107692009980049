
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import LoadingService from "../services/loading.service";
import RouteParams from "../shared/route-params";
import { ExternalLoggingService } from "../api-generated";

@Injectable({
  providedIn: "root"
})
export default class TrackingLogResolver {
  constructor(
    public router: Router,
    private loadingService: LoadingService,
    private logService: ExternalLoggingService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<null | undefined> {
    const id = route.params[RouteParams.ActionId];
    const contractId = route.parent.params[RouteParams.ContractId];
    const itemId = state.url.split('/').pop();
    this.loadingService.start();
    if(id && itemId)
    { 
      const actionId = parseInt(id, 10);
      if (!isNaN(actionId)) { 
        if(actionId === 0)
        {
          return null;
        }
        let action = '';
        switch(actionId)
        {
          // list
          case 1:
            action = 'List'
            break;
          // action
          case 2:
            action = 'My Action'
            break;
          // email
          case 3:
            action = 'Email'
            break;
          // report
          case 4:
            action = 'Report'
            break;
          default:
            break;
        }
        if(action !== '')
        {
          return this.logService.userActionLog({type: 'submittal', itemId, source: action}).pipe(
            map(() => {
              const urlArray = state.url.split('/');
              const actionIdIndex = urlArray.indexOf(id);
              if(actionIdIndex > -1)
              {
                urlArray[actionIdIndex] = '0';
              }
              const newUrl = urlArray.join('/');
              this.router.navigateByUrl(newUrl);
              return null;
            })
          );
        }
      }
    }
    if(contractId)
    {
      this.router.navigateByUrl(`/${contractId}/list`);
    }
    return of(null);
  }
}
