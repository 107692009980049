<div class="maincontainer" *ngIf="submittal">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [entity]="entity" 
        [docs]="docs"
        [defaultTab]="defaultTab"
        [allowMovingFiles]="true"
        (moveResolutionForm)="addFileToAttachment($event,submittalFileType.CommentResolutionForm)"
        (moveResolutionForms)="addFilesToAttachments($event,submittalFileType.CommentResolutionForm)"
        (moveComment)="addFileToAttachment($event, submittalFileType.MarkedUpComments)"
        (moveComments)="addFilesToAttachments($event,submittalFileType.MarkedUpComments)"
      ></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submit()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="submittal">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="Submittal Review"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
            <aecom-form-item
              data-cy="hasComments"
              [label]="'Do you have comments to provide?'"
              columnSpan="1"
            >
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    submittal.hasComments
                      ? 'blue'
                      : 'bg-grey-e'
                  }}"
                  (click)="setHasComments(true)"
                >
                  Yes
                </aecom-button>

                <aecom-button
                  innerClass="btn solid btn-tab {{
                    !submittal.hasComments
                      ? 'blue'
                      : 'bg-grey-e'
                  }}"
                  (click)="setHasComments(false)"
                >
                  No
                </aecom-button>
              </div>
            </aecom-form-item>
              <aecom-form-item
                *ngIf="submittal.hasComments"
                [label]="'Response'"
                [property]="
                  getResponseLength() + '/' + responseMaxLength + ' characters'
                "
                columnSpan="2"
                class="formItemNoMargin"
              >
                <aecom-textbox
                  data-cy="response"
                  rows="6"
                  [theme]="'light'"
                  [notEmpty]="true"
                  [maxlength]="responseMaxLength"
                  [isMultiLine]="true"
                  [(ngValue)]="submittal.Response"
                  (ngValueChange)="setResponse($event)"
                  [validate]="
                    (attemptToIssue && submittal.hasComments && !submittal.Response?.length)
                    || getResponseLength() > responseMaxLength || baseFunctionService.isInputInvalid(submittal.Response)
                  "
                ></aecom-textbox>
              </aecom-form-item>
              <!-- <div columnSpan="6">
                <aecom-checkbox
                  data-cy="acknowledge"
                  [ngValue]="acknowledge"
                  (ngValueChange)="setAcknowledge($event)"
                  [validate]="acknowledge !== true && attemptToIssue"
                >
                  I acknowledge that once I have submitted my response, I will
                  no longer be able to edit.
                </aecom-checkbox>

                <div
                  class="error-message"
                  *ngIf="acknowledge !== true && attemptToIssue"
                >
                  Required Field
                </div>
              </div> -->
            </aecom-form-group>

            <aecom-form-group
              *ngIf="submittal.hasComments"
              columnSpan="3"
              columns="1"
              title="Attachment"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  data-cy="attachment"
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="attachments"
                  [theme]="'light'"
                  (ngValueChange)="importFile($event)"
                  (ngDeleteChange)="deleteFile($event)"
                ></aecom-upload>
                <div class="error-message" *ngIf="!attachmentsNameValid">
                  File Name cannot include special characters other than '-',
                  '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>
