import { ISubDueDate, SubmittalDuedate } from "../api-generated";

export default class SubmittalDueDateDetails
  implements ISubDueDate
{
  public Guid?: string;
  public DueDate?: string | null;
  public InternalDueDate?: string | null;

  constructor(item?: SubmittalDuedate) {

    this.Guid = item?.Guid;

    this.DueDate = item?.DueDate;

    this.InternalDueDate = item?.InternalDueDate;
  }
}
