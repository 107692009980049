import { IPSubCreateItem, ISubCreate } from "../api-generated";

import TimeStampWithDraft from "./timeStampWithDraft";

export default class SubmittalCreateBase
  extends TimeStampWithDraft
  implements ISubCreate
{
  public Guid?: string;

  public SubmittalId?: string;

  public Title?: string;

  public BIM360?: string;
  
  public SpecificationId?: string;

  public MasterSpecId?: string;

  public DocumentTypeId?: string;

  public SubmittalTypeId?: string;

  public DrawingPageId?: string;

  public PhaseId?: string;

  public  ContractRequirementId?: string;

  public Priority?: number;

  public PriorityReason?: string;

  public ReferenceNumber?: string;

  public ReferenceNote?: string;
  public IsConfidentail?: boolean;

  constructor(item?: IPSubCreateItem) {
    super(item);
    this.Guid = item?.Guid;

    // this.SubmittalId = item?.SubmittalId;

    this.Title = item?.Title;

    this.BIM360 = item?.BIM360;

    this.SpecificationId = item?.SpecificationId;

    this.MasterSpecId = item?.MasterSpecId;

    this.DocumentTypeId = item?.DocumentTypeId;

    this.SubmittalTypeId = item?.SubmittalTypeId;

    this.DrawingPageId = item?.DrawingPageId;

    this.PhaseId = item?.PhaseId;

    this.ContractRequirementId = item?.ContractRequirementId;

    this.Priority = item?.Priority ?? 1;

    this.PriorityReason = item?.PriorityReason;

    this.ReferenceNumber = item?.ReferenceNumber;

    this.ReferenceNote = item?.ReferenceNote;
    this.IsConfidentail = item?.IsConfidentail ?? false;
  }
}
