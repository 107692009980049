<div *ngIf="submittalInreviewObj" class="maincontainer light-theme">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="allDocuments"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                data-cy="back"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                back
              </aecom-button>
              <aecom-button
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>
              <!-- <aecom-button
                *ngIf="isDoc"
                data-cy="qaqcFail"
                innerClass="btn lg outline blue"
                (click)="qaqcFail()"
              >
                QA/QC FAIL
              </aecom-button> -->

              <aecom-button
                [theme]="'light'"
                [disabled]="(!hasChanges() || !submittalInreviewObj.IsQAQCCompliance || !submittalInreviewObj.submittal_in_review.IsCompleted) ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                data-cy="submit"
                innerClass="btn lg solid blue"
                (click)="submitSubmittal()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <aecom-form columns="12">
            <ng-container
              *ngIf="submittalInreviewObj.IsQAQCCompliance"
            >
              <aecom-form-group
                columnSpan="12"
                columns="2"
                title="Processing"
                style="margin-bottom: 40px"
              >
                <aecom-form-item
                  data-cy="completed"
                  [label]="'1. Is this technically compliant?'"
                  [required]="true"
                  columnSpan="1"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        submittalInreviewObj.submittal_in_review.IsCompleted
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setIsComplete(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        !submittalInreviewObj.submittal_in_review.IsCompleted
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setIsComplete(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <aecom-form-item columnSpan="1">
                </aecom-form-item>
                <ng-container *ngIf="submittalInreviewObj.submittal_in_review.IsCompleted">
                  <aecom-form-item
                    data-cy="bluebeamNeeded"
                    [label]="'2. Is a Bluebeam Session needed?'"
                    columnSpan="1"
                  >
                    <div class="button-wrapper d-flex">
                      <aecom-button
                        innerClass="btn solid btn-tab {{
                          submittalInreviewObj.IsBluebeamRequired
                            ? 'blue'
                            : 'bg-grey-e'
                        }}"
                        (click)="setIfBlubeamSessionRequired(true)"
                      >
                        Yes
                      </aecom-button>

                      <aecom-button
                        innerClass="btn solid btn-tab {{
                          !submittalInreviewObj.IsBluebeamRequired
                            ? 'blue'
                            : 'bg-grey-e'
                        }}"
                        (click)="setIfBlubeamSessionRequired(false)"
                      >
                        No
                      </aecom-button>
                    </div>
                  </aecom-form-item>
                  <aecom-form-item columnSpan="1">
                  </aecom-form-item>
                  <aecom-form-item [label]="'3.Internal Due Date'" columnSpan="1">
                    <aecom-date
                      id="internalDue"
                      data-cy="internalDue"
                      [weekendsOff]="true"
                      [notValidBefore]="today"
                      [notValidAfter]="maxInternalDueDate"
                      [notEmpty]="true"
                      [theme]="'light'"
                      [ngValue]="internalDueDate"
                      (change)="setDate($event)"
                      [validate]="canIssueSubmittal && !internalDueDate"
                    >
                    </aecom-date>
                  </aecom-form-item>
                  <aecom-form-item [label]="'4.Response Due Date'" columnSpan="1">
                    <aecom-date
                      [weekendsOff]="true"
                      [theme]="'light'"
                      [ngValue]="dueDate"
                      [notEmpty]="true"
                      [notValidBefore]="minDueDate"
                      (change)="setDueDate($event)"
                      [validate]="
                        !dueDate && canIssueSubmittal
                      "
                    ></aecom-date>
                  </aecom-form-item>
                </ng-container>
                
              </aecom-form-group>
              <ng-container *ngIf="submittalInreviewObj.submittal_in_review.IsCompleted">
                <aecom-form-group
                  *ngIf="submittalInreviewObj.IsBluebeamRequired"
                  columnSpan="12"
                  columns="2"
                  title="Bluebeam Session Detail Info"
                  style="margin-bottom: 40px"
                >
                  <aecom-form-item [label]="'Session Name'" columnSpan="1">
                    <aecom-textbox
                      data-cy="sessionName"
                      [theme]="'light'"
                      [notEmpty]="true"
                      [(ngValue)]="
                        submittalInreviewObj.submittal_bluebeam_detail.SessionName
                      "
                      (ngValueChange)="setSessionName($event)"
                      [validate]="
                        (canIssueSubmittal &&
                        (!submittalInreviewObj.submittal_bluebeam_detail.SessionName ||
                          submittalInreviewObj.submittal_bluebeam_detail.SessionName.trim() ==='')) || 
                        baseFunctionService.isInputInvalid(submittalInreviewObj.submittal_bluebeam_detail.SessionName)
                      "
                    ></aecom-textbox>
                  </aecom-form-item>

                  <aecom-form-item [label]="'End Date'" columnSpan="1">
                    <aecom-date
                      data-cy="endDate"
                      [theme]="'light'"
                      [weekendsOff]="true"
                      [ngValue]="bluebeamDueDate"
                      [notValidBefore]="today"
                      [notEmpty]="true"
                      (change)="setBluebeamDueDate($event)"
                      [validate]="canIssueSubmittal && !bluebeamDueDate"
                    ></aecom-date>

                    <div
                      class="error-message"
                      *ngIf="
                        canIssueSubmittal &&
                        bluebeamDueDate &&
                        bluebeamDueDate < today
                      "
                    >
                      Dates need to be after today
                    </div>
                  </aecom-form-item>

                  <aecom-form-item
                    data-cy="endTime"
                    [label]="'End Time'"
                    columnSpan="1"
                  >
                    <input
                      type="time"
                      [value]="bluebeamDueTime"
                      (change)="setTime($event)"
                      (input)="setTime($event)"
                    />

                    <div
                      class="error-message"
                      *ngIf="canIssueSubmittal && !bluebeamDueTime"
                    >
                      Required Field
                    </div>
                  </aecom-form-item>

                  <aecom-form-item
                    [label]="'Invitation message for Reviewers'"
                    [property]="
                      submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage
                        ?.length + '/150 characters'
                    "
                    columnSpan="3"
                  >
                    <aecom-textbox
                      data-cy="invitation"
                      theme="light"
                      [notEmpty]="true"
                      [maxlength]="150"
                      [isMultiLine]="true"
                      [(ngValue)]="
                        submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage
                      "
                      [validate]="
                        (canIssueSubmittal &&
                        (!submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage ||
                          submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage.trim() ==='')) ||
                        baseFunctionService.isInputInvalid(submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage)
                      "
                    ></aecom-textbox>
                  </aecom-form-item>
                </aecom-form-group>

                <aecom-form-group
                  columnSpan="12"
                  columns="1"
                  title="Confirm Reviewer(s)"
                  style="margin-bottom: 15px; position: relative"
                  data-cy="assignReviewer"
                >
                  <div style="width: 100%; height: 250px" class="noTableFilter">
                    <aecom-table
                      class="asignUserTable"
                      [ngValue]="reviewerTableData"
                      (ngValueChange)="reviewerTableRows = $event"
                      [theme]="'light'"
                      innerClass="list"
                    >
                      <tbody>
                        <tr
                          *ngFor="let bRow of reviewerTableRows"
                          class="assignUser_tr_hover"
                        >
                          <ng-container *ngFor="let hCol of tableHeader">
                            <td *ngIf="hCol.Type !== columnType.Hide">
                              <span class="assignUser_td_Item">
                                {{ bRow[hCol.Name] }}
                              </span>
                            </td>
                          </ng-container>

                          <td>
                            <a
                              class="btn"
                              (click)="removeUserClick(bRow, 'reviewer')"
                            >
                              <i
                                class="
                                  icon
                                  icon-social_user_remove
                                  icon-orange icon-lg
                                "
                              ></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </aecom-table>

                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                      *ngIf="reviewerTableData?.length === 0"
                    >
                      <div class="noItem-body">
                        <div>
                          <div
                            class="
                              noItem-icon-row
                              icon
                              icon-social_user_team_big
                              icon-grey icon-xl
                            "
                          ></div>
                        </div>

                        <div class="noItem-title-row">None</div>
                      </div>
                    </div>
                  </div>

                  <!-- <div
                  class="error-message"
                  style="position: absolute; top: -2px; left: 140px"
                  *ngIf="reviewerTableData.length === 0"
                >
                  Required Field
                </div> -->

                  <div
                    style="
                      position: absolute;
                      top: 7px;
                      right: 0;
                      cursor: pointer;
                    "
                  >
                    <div
                      data-cy="addReviewer"
                      class="formGroupManu"
                      (click)="openWindowClick('reviewer')"
                    >
                      + Add REVIEWERS
                    </div>
                  </div>
                </aecom-form-group>

                <aecom-form-group
                  columnSpan="12"
                  columns="1"
                  title="Confirm OBSERVER(s)"
                  style="position: relative"
                  data-cy="assignWatcher"
                >
                  <div style="width: 100%; height: 250px" class="noTableFilter">
                    <aecom-table
                      class="asignUserTable"
                      [ngValue]="watcherTableData"
                      (ngValueChange)="watcherTableRows = $event"
                      [theme]="'light'"
                      innerClass="list"
                    >
                      <tbody>
                        <tr
                          *ngFor="let bRow of watcherTableRows"
                          class="assignUser_tr_hover"
                        >
                          <ng-container *ngFor="let hCol of tableHeader">
                            <td *ngIf="hCol.Type !== columnType.Hide">
                              <span class="assignUser_td_Item">
                                {{ bRow[hCol.Name] }}
                              </span>
                            </td>
                          </ng-container>

                          <td>
                            <a
                              class="btn"
                              (click)="removeUserClick(bRow, 'watcher')"
                              ><i
                                class="
                                  icon
                                  icon-social_user_remove
                                  icon-orange icon-lg
                                "
                              ></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </aecom-table>

                    <div
                      style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                      *ngIf="watcherTableData?.length === 0"
                    >
                      <div class="noItem-body">
                        <div>
                          <div
                            class="
                              noItem-icon-row
                              icon
                              icon-social_user_team_big
                              icon-grey icon-xl
                            "
                          ></div>
                        </div>

                        <div class="noItem-title-row">None</div>
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      position: absolute;
                      top: 7px;
                      right: 0;
                      cursor: pointer;
                    "
                  >
                    <div
                      data-cy="addWatcher"
                      class="formGroupManu"
                      (click)="openWindowClick('watcher')"
                    >
                      + Add OBSERVERS
                    </div>
                  </div>
                </aecom-form-group>
              </ng-container>
              
            </ng-container>
            <ng-container *ngIf="!submittalInreviewObj.IsQAQCCompliance || !submittalInreviewObj.submittal_in_review.IsCompleted">
              <rejectForm style="grid-column: span 12 / auto;"
                [(submittalRejectDetail)]="submittalInreviewObj.submittal_reject_detail" 
                [(coverSheet)]="coverSheet" 
                [(removedFileIds)]="removedFileIds" 
                [entity]="entity" 
                [tempId]="submittalInreviewObj.tempId" 
                [canIssueSubmittal]="canIssueSubmittal">
              </rejectForm>
            </ng-container>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      data-cy="selectUser"
      [tableData]="tableRows"
      [title]="selectUserWindowTitle"
      (close)="closeWindow($event)"
      [(activeSave)]="activeSave"
    ></select-user>
  </div>
</div>
