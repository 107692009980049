import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import SubmittalDocumentCopy from "@models/submittalDocumentCopy";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalRole from "@models/submittalRole";
import FileManagementService from "@services/fileManagement.service";
import LocalStorageService from "@services/local-storage.service";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import { reviewSubmittalTypeId } from "@shared/staticValue";
import { dispositionFormName, getFilesByType, getVersionString, hasChangesFileModel, isFileNameInvalid, isReopened, showDenied } from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  EmailService,
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  SubmittalDocumentsService,
  SubmittalReviewerService,
  SubmittalWatcherService,
} from "src/app/api-generated";
import environment from "src/environments/environment";
import AzureBlobService from "@services/azureBlob.service";
import SubmittalDisposition from "@models/submittalDisposition";
import FileModelWithVersion from "@models/fileModelWithVersion";
import SubmittalDelegate from "@models/submittalDelegate";
import BaseFunctionService from "@services/baseFunction.service";
import LocalSubmittalDocumentTypeService from "@services/local-submittalDocumentType.service";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
@Component({
  selector: "app-ddc-disposition",
  templateUrl: "./ddcDisposition.component.html",
  styleUrls: ["./ddcDisposition.component.scss"],
})
export default class DDCDispositionComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  contractId: string;

  submittal: SubmittalDisposition;

  oldSubmittal: SubmittalDisposition;

  submittalDelegate: SubmittalDelegate;

  entity: IPSubItem;

  attemptToIssue = false;

  acknowledge = false;

  noteMaxLength = 1500;

  currentUser: string;

  coverSheet: FileModel[] = [];

  commentResolutionForm: FileModel[] = [];

  markedUpComments: FileModel[] = [];

  prevCoverSheet: FileModel[] = [];

  prevCommentResolutionForm: FileModel[] = [];

  prevMarkedUpComments: FileModel[] = [];

  coverSheetNameValid = true;

  commentResolutionFormNameValid = true;

  markedUpCommentsNameValid = true;

  enableSaveDraft = false;

  defaultTab = "details";

  requireBluebeam = true;

  skipCompile = false;

  docs: IPSubDocumentDownload[] = [];

  showReview = true;

  isDBAdmin = false;

  showDispositionForm = false;

  dispositionForm: SubmittalDisposition | null = null;

  attachments: FileModel[];

  private removedFileIds: string[] = [];

  ddcManagersList: ISelectItem[] = [];

  showDispositionTab = false;
  showDraftDispositionTab = false;

  NeedDelegate = false;

  showDenied = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalReviewerService: SubmittalReviewerService,
    public submittalWatcherService: SubmittalWatcherService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public localContractUserService: LocalContractUserService,
    public emailService: EmailService,
    public localStorageService: LocalStorageService,
    public baseFunctionService: BaseFunctionService,
    public localSubmittalDocumentTypeService: LocalSubmittalDocumentTypeService,
  ) {}

  ngOnInit(): void {
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;
    this.currentUser = data.currentUserId;
    this.contractId = data.submittal.ContractId;
    this.entity = data.submittal;
    this.showDenied = showDenied(this.entity, this.localSubmittalDocumentTypeService);
    this.docs = data.documents;
    this.ddcManagersList = data.ddcManagersList.filter((u)=>{return u.id !== this.currentUser && u.id !== this.entity.DDCManagerId});
    this.requireBluebeam = this.entity.IsBluebeamRequired === true;
    if(this.entity.submittal_disposition && this.entity.submittal_disposition.length > 0) 
      {
        this.showDispositionTab = this.entity.submittal_disposition.find((d)=>{return d.IsDraft === false && d.ToDDC !== true && d.ToSrManager !== true}) !== undefined;
        this.showDraftDispositionTab = this.entity.submittal_disposition.find((d)=>{return d.IsDraft === false && d.ToDDC === true}) !== undefined;
      }
    this.showReview =
      this.entity.submittal_create[0].SubmittalTypeId === reviewSubmittalTypeId;

    this.skipCompile = this.entity.ManagerId === this.entity.CoordinatorId;

    this.submittalDelegate = new SubmittalDelegate(data.submittal.ContractId, data.submittal);

    this.oldSubmittal = new SubmittalDisposition(
      data.submittal.ContractId,
      false,
      data.submittal,
    );
    let crfs: FileModelWithVersion[] = [];
    let mucs: FileModelWithVersion[] = [];
    if(data.documents && data.documents.length > 0)
    {
      if (this.oldSubmittal.submittal_disposition.Guid) {
        const documnetsForThis = data.documents.filter((item) => {
          return item.ReferenceId === this.oldSubmittal.submittal_disposition.Guid;
        });
  
        console.log("all documents", documnetsForThis);
  
        this.coverSheet = getFilesByType(
          documnetsForThis,
          SubmittalFileType.CoverSheetStampSigned,
        );
  
        this.commentResolutionForm = getFilesByType(
          documnetsForThis,
          SubmittalFileType.CommentResolutionForm,
        );
  
        this.markedUpComments = getFilesByType(
          documnetsForThis,
          SubmittalFileType.MarkedUpComments,
        );
  
        if(this.coverSheet.find((cs)=>{return cs.Name===dispositionFormName(this.entity.SubmittalNumber)}))
        {
          this.dispositionForm = _.cloneDeep(this.oldSubmittal);
        }
  
        this.prevCoverSheet = [...this.coverSheet];
  
        this.prevCommentResolutionForm = [...this.prevCommentResolutionForm];
  
        this.prevMarkedUpComments = [...this.prevMarkedUpComments];
      } else {
        const compiles = (this.entity?.submittal_compile ?? []).concat(this.entity?.submittal_disposition??[]);
        if(compiles && compiles.length>0)
        {
          const lastCompile = compiles.sort(
            (a, b) =>
              new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
          )[0];
          if(lastCompile.IsDraft!==true)
          {
            const documnetsForThis = data.documents.filter((doc) => {
              return doc.ReferenceId === lastCompile.Guid;
            });

            crfs = getFilesByType(
              documnetsForThis,
              SubmittalFileType.CommentResolutionForm,
            );
        
            mucs = getFilesByType(
              documnetsForThis,
              SubmittalFileType.MarkedUpComments,
            );
          }
        }
      }
    }
    this.submittal = _.cloneDeep(this.oldSubmittal);
    if(crfs.length > 0)
    {
      this.addFilesToAttachments(crfs, SubmittalFileType.CommentResolutionForm);
    }
    if(mucs.length > 0)
    {
      this.addFilesToAttachments(mucs, SubmittalFileType.MarkedUpComments)
    }
    this.defaultTab = "draft";

    this.isDBAdmin = data.isDBAdmin;
    if(this.entity.Viewed !== true && isReopened(this.entity))
    {
      this.baseFunctionService.readReopened();
    }
    this.loadingService.stop();
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldSubmittal);
    const current = JSON.stringify(this.submittal);
    
    return (
      old !== current || hasChangesFileModel(this.coverSheet, this.prevCoverSheet)
      || hasChangesFileModel(this.commentResolutionForm, this.prevCommentResolutionForm)
      || hasChangesFileModel(this.markedUpComments, this.prevMarkedUpComments)
    );
  }

  IsInputValid(): boolean {
    return this.getNoteLength() <= this.noteMaxLength &&
    !this.baseFunctionService.isInputInvalid(this.submittal.submittal_disposition.Note);
  }

  IsValid(): boolean {
    if ((this.NeedDelegate && _.isEmpty(this.submittalDelegate.submittal_delegate_detail.UserId)) ||
      (!this.NeedDelegate && (!this.submittal.submittal_disposition.Note ||
      this.submittal.submittal_disposition.Note.trim() === "" ||
      !this.submittal.submittal_disposition.Disposition ||
      this.submittal.submittal_disposition.Disposition > 4 ||
      this.submittal.submittal_disposition.Disposition < 1 ||
      this.coverSheet.length === 0 ||
      ((this.submittal.submittal_disposition.Disposition !== 2 && this.submittal.submittal_disposition.Disposition !== 4)
        ? this.commentResolutionForm.length === 0
        : false) ||
      !this.isFileNameValid()))
    ) {
      return false;
    } 
    return true;
  }

  submit(): void {
    this.attemptToIssue = true;
    const validation = this.IsValid() && this.IsInputValid();
    if (!validation) {
      return;
    }

    /* pop up message needs to be update.
    if status is disposition and you are the manager,
     the pop up should say

     "Your Submittal will be sent to the Doc Control to close Bluebeam Session and upload package."
    */
    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      if(this.NeedDelegate)
      {
        this.update();
      }
      else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Confirm Action?";
        modalInstance.instance.body = 
          '<span class="warningFont">The official response will be sent to the DB and will not be modifiable. Please verify that all information & attachments are correct.</span>';
        modalInstance.result.then((result) => {
          if (result === 1) {
            this.submittal.submittal_disposition.IsDraft = false;
            this.update();
          }
        });
      }
      
    }
  }

  saveDraft(): void {
    if (!this.hasChanges()||!this.isFileNameValid()||!this.IsInputValid()) return;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your Submittal will save as draft.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.submittal.submittal_disposition.IsDraft = true;
          this.update();
        }
      });
    }
  }

  update(): void {
    this.loadingService.start();
    this.updateFiles();
    this.NeedDelegate === true ? this.baseFunctionService.subDelegate(this.submittalDelegate) : this.baseFunctionService.update(this.submittal);
  }

  sendBack(): void {
    this.baseFunctionService.sendBack();
  }

  updateFiles(): void {
    const allCurrentFiles = [
      ...this.coverSheet,
      ...this.commentResolutionForm,
      ...this.markedUpComments,
    ];
    const allOldFiles = [
      ...this.prevCoverSheet.map((f) => f.Guid),
      ...this.prevCommentResolutionForm.map((f) => f.Guid),
      ...this.prevMarkedUpComments.map((f) => f.Guid),
    ];

    const filesToAdd = allCurrentFiles.filter((f) => {
      return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = allCurrentFiles.map((f) => {
      return f.Guid;
    });

    const filesToRemove = allOldFiles.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  setNeedDelegate(e: boolean): void {
    this.NeedDelegate = e;
    // 
  }

  setDDCManager(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.submittalDelegate.submittal_delegate_detail.UserId = e?.id;
      // 
    }
  }

  setDisposition(e: number): void {
    if(!this.dispositionForm)
    {
      this.submittal.submittal_disposition.Disposition = e;
      if (e === 2) {
        this.markedUpComments.forEach((item) => {
          this.deleteFile(item, SubmittalFileType.MarkedUpComments);
        });
      }
      
    } else if(this.dispositionForm.submittal_disposition.Disposition !== e) {
      this.dispositionFormAlert();
    }
  }

  dispositionFormAlert() {
    this.baseFunctionService.dispositionFormAlert();
  }

  NoDispositionAlert() {
    this.baseFunctionService.NoDispositionAlert();
  }

  generateDispositionForm() {
    if([1,2,3,4].includes(this.submittal.submittal_disposition.Disposition))
    {
      this.dispositionForm = _.cloneDeep(this.submittal);
      this.attachments = _.cloneDeep(this.markedUpComments).concat(_.cloneDeep(this.commentResolutionForm));
      this.showDispositionForm = true;
    } else {
      this.NoDispositionAlert();
    }
    
  }
  closeDispositionForm(e: File | null) {
    console.log(e);
    if(e)
    {
      const fileModule = new FileModel('', e.name, '', '', 'uploading', 0, e);
      this.importFile([fileModule], SubmittalFileType.CoverSheetStampSigned);
    }
    this.showDispositionForm = false;
  }
  setNote(e: string): void {
    // if(!this.dispositionForm)
    // {
      this.submittal.submittal_disposition.Note = e;
      
    // } else if(this.dispositionForm.submittal_disposition.Note !== e) {
    //   this.dispositionFormAlert();
    // }
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
    
  }

  requestComments(): void {
    this.baseFunctionService.requestComments();
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      console.log(type);
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            getVersionString(this.submittal.SubmittalNumber),
            this.submittal.Status,
            this.submittal.Guid,
            this.submittal.submittal_disposition.Guid &&
            this.submittal.submittal_disposition.Guid !== ""
              ? this.submittal.submittal_disposition.Guid
              : this.submittal.tempId,
          );
          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    if(e.Name === dispositionFormName(this.entity.SubmittalNumber) && type === SubmittalFileType.CoverSheetStampSigned)
    {
      this.dispositionForm = null;
    }
    
  }

  addFileToAttachment(prevItem: FileModel, type: string): void {
    const item = _.cloneDeep(prevItem);
    item.Percentage = 100;
    item.Status = UploadStatus.PRELOADED;

    if (!this.isFileExist(prevItem, type)) {
      const fileUploaded = new SubmittalDocumentCopy(
        type,
        getVersionString(this.submittal.SubmittalNumber),
        this.submittal.Status,
        this.submittal.Guid,
        this.submittal.submittal_disposition.Guid &&
        this.submittal.submittal_disposition.Guid !== ""
          ? this.submittal.submittal_disposition.Guid
          : this.submittal.tempId,
        item.Guid,
      );

      this.submittalDocumentService
        .copySubmittalDocument(fileUploaded)
        .subscribe((res) => {
          item.Guid = res.Guid;
          this.updateFileList(item, type);
          
        });
    }
  }

  addFilesToAttachments(prevItems: FileModel[], type: string): void {
    const items = _.cloneDeep(prevItems);
    items.forEach((item) => {
      this.addFileToAttachment(item, type);
    });
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getNoteLength(): number {
    return this.submittal.submittal_disposition?.Note
      ? this.submittal.submittal_disposition?.Note.length
      : 0;
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case SubmittalFileType.CoverSheetStampSigned:
        index = this.coverSheet.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.coverSheet.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.coverSheet.push(item);
            this.coverSheet = this.coverSheet.slice();
          }
        }

        break;
      case SubmittalFileType.CommentResolutionForm:
        index = this.commentResolutionForm.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.commentResolutionForm.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.commentResolutionForm.push(item);
            this.commentResolutionForm = this.commentResolutionForm.slice();
          }
        }
        break;
      case SubmittalFileType.MarkedUpComments:
        index = this.markedUpComments.findIndex((file) => {
          return file.Name === item.Name;
        });
        if (isRemove) {
          if (index !== -1) {
            this.markedUpComments.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.markedUpComments.push(item);
            this.markedUpComments = this.markedUpComments.slice();
          }
        }
        break;
    }
    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
    this.isFileNameValid();
    console.log(this.coverSheet);
  }

  isFileNameValid(): boolean {
    this.coverSheetNameValid = true;
    this.coverSheet.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.coverSheetNameValid = false;
      }
    });
    this.commentResolutionFormNameValid = true;
    this.commentResolutionForm.forEach((f) => {
      if (f && f.Name) {
        if (isFileNameInvalid(f.Name)) {
          this.commentResolutionFormNameValid = false;
        }
      }
    });
    this.markedUpCommentsNameValid = true;
    this.markedUpComments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.markedUpCommentsNameValid = false;
      }
    });
    return (
      this.coverSheetNameValid &&
      this.commentResolutionFormNameValid &&
      this.markedUpCommentsNameValid
    );
  }

  isFileExist(item: FileModel, type: string): boolean {
    let index = -1;
    switch (type) {
      case SubmittalFileType.CoverSheetStampSigned:
        index = this.coverSheet.findIndex((file) => {
          return file.Name === item.Name;
        });
        break;
      case SubmittalFileType.CommentResolutionForm:
        index = this.commentResolutionForm.findIndex((file) => {
          return file.Name === item.Name;
        });
        break;
      case SubmittalFileType.MarkedUpComments:
        index = this.markedUpComments.findIndex((file) => {
          return file.Name === item.Name;
        });
        break;
    }
    return index > -1;
  }

  hasFilesUploading(): boolean {
    return (
      this.coverSheet.some((item) => item.Status === UploadStatus.UPLOADING) ||
      this.commentResolutionForm.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      ) ||
      this.markedUpComments.some(
        (item) => item.Status === UploadStatus.UPLOADING,
      )
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }
  reassign(): void {
    this.baseFunctionService.reassign();
  }
}

//MAy be use later
// this.dispositionReportService.submittal = this.entity;
// this.dispositionReportService.subDisposition= this.submittal;
// this.dispositionReportService.contractId =  this.contractId;
// this.dispositionReportService.showReview = this.showReview;
// this.dispositionReportService.dbNote = this.submittal.SubmittalDisposition.Note;
