import { ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";
import SubmittalUpdateDueDateStep from "@models/submittalUpdateDueDateStep";
import _ from "lodash";

@Component({
  selector: "app-updateDueDate-modal",
  templateUrl: "./updateDueDate-modal.component.html",
  styleUrls: ["./updateDueDate-modal.component.scss"]
})
export class UpdateDueDateModalComponent {
  item: SubmittalUpdateDueDateStep;

  internalDueDate: Date;

  dueDate: Date;

  public maxInternalDueDate: string | undefined = undefined;

  public minDueDate: string | undefined = undefined;

  private previousDueDate: string | undefined = undefined;

  private previousInternalDueDate: string | undefined = undefined;

  set dueDateItem(due: SubmittalUpdateDueDateStep) {
    this.item = due;
    this.internalDueDate = due.submittal_duedate.InternalDueDate
      ? new Date(due.submittal_duedate.InternalDueDate.replace("00:00:00.000Z", "12:00:00.000Z"))
      : new Date();
    this.dueDate = due.submittal_duedate.DueDate
      ? new Date(due.submittal_duedate.DueDate.replace("00:00:00.000Z", "12:00:00.000Z"))
      : new Date();
    this.maxInternalDueDate = this.dueDate.toLocaleDateString();
    this.minDueDate = this.internalDueDate.toLocaleDateString();
    this.previousDueDate = this.dueDate.toLocaleDateString();
    this.previousInternalDueDate = this.internalDueDate.toLocaleDateString();
  }

  constructor(
    private activeModal: ModalContainerService,
  ) {}

  save() {
    if (this.isValid()) {
      this.activeModal.close(this.item);
    }
  }

  isValid(): boolean {
    return !_.isEmpty(this.item.submittal_duedate.InternalDueDate) && !_.isEmpty(this.item.submittal_duedate.DueDate)
  }

  cancel() {
    this.activeModal.close(null);
  }

  canIssue(): boolean {
    return this.internalDueDate.toLocaleDateString() !== this.previousInternalDueDate || this.dueDate.toLocaleDateString() !== this.previousDueDate;
  }
  
  setDate(e: Date): void {
    this.internalDueDate = new Date(e);
    this.item.submittal_duedate.InternalDueDate = new Date(e).toISOString();
    this.minDueDate = this.internalDueDate.toLocaleDateString();
  }

  setDueDate(e: Date): void {
    this.dueDate = new Date(e);
    this.item.submittal_duedate.DueDate = new Date(e).toISOString();
    this.maxInternalDueDate = this.dueDate.toLocaleDateString();
  }
}
