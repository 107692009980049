<div class="row" style="margin-bottom: 20px">
  <div class="col-12">
    <aecom-form columns="12">
      <aecom-form-group
        columnSpan="12"
        columns="2"
        title="QA/QC Check Failure Form Details"
        style="margin-bottom: 40px"
      >
        <aecom-form-item [label]="'Failure Reasons'" columnSpan="1">
          <aecom-dropdown
            data-cy="reasons"
            [multipleSelect]="true"
            [hideAll]="true"
            [theme]="'light'"
            placeholder="Select"
            [data]="failureReasonsList"
            (ngValueChange)="setFailureReason($event)"
            [selectedId]="selectedReason"
            [validate]="
              (!submittalRejectDetail.Reason ||
              submittalRejectDetail.Reason.trim() === '') &&
              canIssueSubmittal
            "
          ></aecom-dropdown>
        </aecom-form-item>
        <aecom-form-item
          columnSpan="2"
          [label]="'Remarks (Optional)'"
          [property]="
          submittalRejectDetail.Note.length +
            '/1500 characters'
          "
        >
          <aecom-textbox
            data-cy="rejectNote"
            [theme]="'light'"
            [(ngValue)]="submittalRejectDetail.Note"
            placeholder="Reason"
            rows="6"
            [maxlength]="1500"
            [isMultiLine]="true"
            [validate]="baseFunctionService.isInputInvalid(submittalRejectDetail.Note)"
          ></aecom-textbox>
        </aecom-form-item>

      </aecom-form-group>
      <aecom-form-group
        columnSpan="6"
        title="QA/QC Check Failure Form"
        style="margin-bottom: 40px"
      >
        <aecom-form-item columnSpan="1">
          <aecom-upload
            data-cy="failureForm"
            [isNewStyle]="true"
            [multiFiles]="true"
            [canDelete]="true"
            [data]="coverSheet"
            [theme]="'light'"
            [onlyGenerated]="true"
            (generateFile)="generateFailureForm()"
            (ngDeleteChange)="
              deleteFile($event, submittalFileType.CoverSheetStampSigned)
            "
          ></aecom-upload>

          <div
            class="error-message"
            *ngIf="coverSheet.length === 0 && canIssueSubmittal"
          >
            Required Field
          </div>
        </aecom-form-item>
      </aecom-form-group>
    </aecom-form>
  </div>
</div>

<div *ngIf="showFailureForm" class="up-contentcontainer">
  <app-failureForm [submittal]="entity" [failureForm]="submittalRejectDetail" (closeWindow)="closeFailureForm($event)"></app-failureForm>
</div>