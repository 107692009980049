import { getLatestDueDateItem } from "@shared/utils";
import { IPSubItem, ISubUpdateDueDate } from "../api-generated";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalDueDateDetails from "./submittalDueDateDetail";

export default class SubmittalUpdateDueDateStep
  extends SubmittalItemUpdateBase
  implements ISubUpdateDueDate
{
  public submittal_duedate: SubmittalDueDateDetails;

  constructor(contractId: string, sub: IPSubItem) {
    super(contractId, sub);
    const lastDueDateItem = getLatestDueDateItem(sub);
    this.submittal_duedate = new SubmittalDueDateDetails(lastDueDateItem);
    delete this.submittal_duedate.Guid;
  }
}
