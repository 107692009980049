import { v4 as uuidv4 } from 'uuid';
import { IPSubItem, ISubInReviewStep } from "../api-generated";
import SubmittalBluebeam from "./submittalBluebeam";
import SubmittalInReviewBase from "./submittalInReviewBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalRejectDetail from "./submittalRejectDetail";
import SubmittalNotesBase from "./submittalNotesBase";
import { getDateFormatted_MMDDYYYY, getDateWithOffSet, getLatestDueDateItem } from "@shared/utils";
import SubmittalDueDateDetails from './submittalDueDateDetail';

export default class SubmittalInReview
  extends SubmittalItemUpdateBase
  implements ISubInReviewStep
{
  public submittal_in_review: SubmittalInReviewBase;

  public tempId?: string;

  public IsBluebeamRequired?: boolean;

  public IsQAQCCompliance?: boolean;

  public submittal_bluebeam_detail?: SubmittalBluebeam;

  public submittal_reject_detail?: SubmittalRejectDetail;

  public submittal_reviewer: string[];

  public submittal_watcher: string[];

  public submittal_notes?: SubmittalNotesBase;

  public submittal_duedate: SubmittalDueDateDetails;

  constructor(contractId: string, item?: IPSubItem) {
    super(contractId, item);

    const issueDate = new Date(getDateWithOffSet(item.DateSubmitted));

    issueDate.setDate(issueDate.getDate()+7);

    const standIssueDate = new Date(getDateFormatted_MMDDYYYY(issueDate));

    const today = new Date(getDateFormatted_MMDDYYYY(new Date()));

    this.IsBluebeamRequired = item?.IsBluebeamRequired ?? true;

    this.IsQAQCCompliance = item?.IsQAQCCompliance;

    this.submittal_reviewer =
      item.submittal_reviewer?.map((i) => {
        return i.ReviewerId ?? "";
      }) ?? [];

    this.submittal_watcher =
      item.submittal_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];

    this.submittal_in_review = new SubmittalInReviewBase(
      item.submittal_in_review[0],
    );
    this.submittal_duedate = new SubmittalDueDateDetails(getLatestDueDateItem(item));
    if(this.submittal_in_review.IsDraft!==true)
    {
      delete this.submittal_duedate.Guid;
      if(standIssueDate >= today)
      {
        this.submittal_duedate.InternalDueDate = standIssueDate.toLocaleDateString();
      }
    }
    
    
    this.submittal_bluebeam_detail = new SubmittalBluebeam(
      item.submittal_bluebeam_detail[0],
    );

    if (!this.submittal_reject_detail) {
      this.tempId = uuidv4();
      this.submittal_reject_detail = new SubmittalRejectDetail();
    }
    const notes = item.submittal_notes?.find((notes)=>{return notes.IsDraft === true && notes.Status === item.Status && notes.DeletedBy === null});
    if(notes)
    {
      this.submittal_notes = new SubmittalNotesBase(notes);
    }
    else 
    {
      this.submittal_notes = new SubmittalNotesBase();
    }
  }
}
