import {
  FileModel,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import tableHeader from "@content/selectUser/table-header";
import TableHeaderCol from "@content/selectUser/table-header-col";
import RowItem from "@content/selectUser/table-row";
import ColumnType from "@models/columnType";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalInReview from "@models/submittalInReview";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import BaseFunctionService from "@services/baseFunction.service";
import LoadingService from "@services/loading.service";
import { assignReviewersTitle, assignWatchersTitle } from "@shared/staticValue";
import {
  getDateFormatted_MMDDYYYY,
  getFilesByType,
  getFormattedTime,
  hasChangesFileModel,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPSubItem,
  IPSubDocumentDownload,
} from "src/app/api-generated";

@Component({
  selector: "app-inreview",
  templateUrl: "./inreview.component.html",
  styleUrls: ["./inreview.component.scss"],
})
export default class InreviewComponent implements OnInit {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  //letiables
  allUserTableRows: RowItem[] = [];
  contractId: string;
  currentUser: string;
  isDoc = false;
  submittalInreviewObj: SubmittalInReview;
  oldSubInreviewObj: SubmittalInReview;
  entity: IPSubItem;

  reviewerWindowTitle = assignReviewersTitle;
  reviewerTableData: RowItem[] = [];
  reviewerTableRows: RowItem[] = [];

  watcherWindowTitle = assignWatchersTitle;
  watcherTableData: RowItem[] = [];
  watcherTableRows: RowItem[] = [];

  selectUserWindowTitle: string;

  showSelectUser = false;
  defaultTab = "details";

  enableSaveDraft = false;

  canIssueSubmittal = false;
  tableRows: RowItem[] = [];
  SubmittalDocuments: IPSubDocumentDownload[];

  internalDueDate: Date;

  dueDate: Date;

  public maxInternalDueDate: string | undefined = undefined;

  public minDueDate: string | undefined = undefined;

  bluebeamDueDate: Date | null = null;
  bluebeamDueTime: string;

  tableHeader: TableHeaderCol[] = tableHeader;
  activeSave = false;
  columnType = ColumnType;

  coverSheet: FileModel[] = [];
  oldCoverSheet: FileModel[] = [];

  coverSheetNameValid = true;

  isManager = false;
  today: Date;

  removedFileIds: string[] = [];

  allDocuments: IPSubDocumentDownload[];
  isDBAdmin = false;
  notesMaxLength = 250;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.today = new Date(getDateFormatted_MMDDYYYY(new Date()));
    // this.today.setDate(new Date().getDate() + 1);

    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;

    console.log("data", data);
    this.currentUser = data.currentUserId;
    this.entity = data.submittal;
    if(this.entity.submittal_notes?.length > 0)
    {
      const notes = this.entity.submittal_notes.find((n)=>{
        return n.IsDraft === false && n.Notes && n.DeletedBy === null && n.Notes.trim() !== ""
      });
      if(notes)
      {
        this.defaultTab = "processing";
      }
    }
    this.contractId = this.entity.ContractId;

    // if (
    //   this.entity.submittal_create[0].SubmittalTypeId &&
    //   this.entity.submittal_create[0].SubmittalTypeId === reviewSubmittalTypeId
    // ) {
    //   this.reviewFlowList.push({
    //     id: "3",
    //     name: "[Level 3 - Manager Review Only]",
    //     checked: false,
    //   });
    // }

    this.oldSubInreviewObj = new SubmittalInReview(
      this.contractId,
      this.entity,
    );

    this.allUserTableRows = data.allUsers;

    if (this.oldSubInreviewObj.submittal_bluebeam_detail?.Due) {
      this.bluebeamDueDate = new Date(
        this.oldSubInreviewObj.submittal_bluebeam_detail.Due,
      );
      this.bluebeamDueTime = getFormattedTime(
        this.oldSubInreviewObj.submittal_bluebeam_detail.Due,
      );
    }

    this.reviewerTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubInreviewObj.submittal_reviewer.includes(u.id);
    });

    this.watcherTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubInreviewObj.submittal_watcher.includes(u.id);
    });

    this.internalDueDate = this.oldSubInreviewObj.submittal_duedate.InternalDueDate
    ? new Date(this.getDateWithOffSet(this.oldSubInreviewObj.submittal_duedate.InternalDueDate))
    : new Date();
    this.dueDate = this.oldSubInreviewObj.submittal_duedate.DueDate
      ? new Date(this.getDateWithOffSet(this.oldSubInreviewObj.submittal_duedate.DueDate))
      : new Date();

    const documentForThis = data.documents.filter((item) => {
      return (
        item.ReferenceId === this.oldSubInreviewObj.submittal_reject_detail.Guid
      );
    });

    this.allDocuments = data.documents;

    this.oldCoverSheet = getFilesByType(
      documentForThis,
      SubmittalFileType.CoverSheetStampSigned,
    );

    this.coverSheet = _.cloneDeep(this.oldCoverSheet);

    this.submittalInreviewObj = _.cloneDeep(this.oldSubInreviewObj);

    this.isDBAdmin = data.isDBAdmin;

    this.isDoc = data.isDoc;
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldSubInreviewObj);
    const current = JSON.stringify(this.submittalInreviewObj);

    return hasChangesFileModel(this.coverSheet, this.oldCoverSheet) || old !== current;
  }

  removeUserClick(row: RowItem, role: string): void {
    if (role === "reviewer") {
      this.reviewerTableData = this.reviewerTableData.filter(
        (reviewer) => reviewer.id !== row.id,
      );
      this.submittalInreviewObj.submittal_reviewer = this.reviewerTableData.map((u) => {
        return u.id;
      });
    } else {
      this.watcherTableData = this.watcherTableData.filter(
        (watcher) => watcher.id !== row.id,
      );
      this.submittalInreviewObj.submittal_watcher = this.watcherTableData.map((u) => {
        return u.id;
      });
    }

    
  }

  openWindowClick(role: string): void {
    this.activeSave = false;

    this.selectUserWindowTitle =
      role === "reviewer" ? this.reviewerWindowTitle : this.watcherWindowTitle;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        if (this.selectUserWindowTitle == this.reviewerWindowTitle) {
          return !this.submittalInreviewObj.submittal_reviewer.includes(user.id);
        } else {
          return !this.submittalInreviewObj.submittal_watcher.includes(user.id);
        }
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        if (this.selectUserWindowTitle === this.reviewerWindowTitle) {
          this.reviewerTableData = this.reviewerTableData.concat(e);
          this.submittalInreviewObj.submittal_reviewer = this.reviewerTableData.map(
            (u) => {
              return u.id;
            },
          );
        } else {
          this.watcherTableData = this.watcherTableData.concat(e);
          this.submittalInreviewObj.submittal_watcher = this.watcherTableData.map(
            (u) => {
              return u.id;
            },
          );
        }

        this.showSelectUser = false;
        
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  IsInputValid(): boolean {
    if (this.submittalInreviewObj.submittal_in_review.IsCompleted && this.submittalInreviewObj.IsQAQCCompliance) {
      let bluebeamValid = true;
      if (this.submittalInreviewObj.IsBluebeamRequired === true) {
        bluebeamValid = this.submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage.length < 150 &&
        !this.baseFunctionService.isInputInvalid(this.submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage) &&
        !this.baseFunctionService.isInputInvalid(this.submittalInreviewObj.submittal_bluebeam_detail.SessionName)
      }

      return this.submittalInreviewObj.submittal_notes.Notes.trim().length <= this.notesMaxLength 
        && !this.baseFunctionService.isInputInvalid(this.submittalInreviewObj.submittal_notes.Notes)
        && bluebeamValid;
    } else {
      return !this.baseFunctionService.isInputInvalid(this.submittalInreviewObj.submittal_reject_detail.Note);
    }
  }

  IsValid(): boolean {
    console.log("IsValid", this.submittalInreviewObj);

    if (this.submittalInreviewObj.submittal_in_review.IsCompleted && this.submittalInreviewObj.IsQAQCCompliance) {
      if (this.submittalInreviewObj.IsBluebeamRequired &&
        (
          _.isEmpty(this.submittalInreviewObj.submittal_bluebeam_detail.InvitationMessage) ||
          _.isEmpty(this.submittalInreviewObj.submittal_bluebeam_detail.SessionName) ||
          _.isNil(this.bluebeamDueTime) ||
          _.isNil(this.bluebeamDueDate) ||
          this.bluebeamDueDate < this.today)
      ) {
        return false;
      }

      return (
        !_.isEmpty(this.submittalInreviewObj.submittal_duedate.InternalDueDate) && !_.isEmpty(this.submittalInreviewObj.submittal_duedate.DueDate)
      );
    } else {
      return this.submittalInreviewObj.submittal_reject_detail?.Reason && this.coverSheet.length > 0;
    }
  }

  submitSubmittal(): void {
    this.canIssueSubmittal = true;

    const validation = this.IsInputValid() && this.IsValid();

    if (validation) {
      if (this.hasFilesUploading()) {
        this.showFileProgressNotification();
      } else {
        this.doSubmit();
      }
    }
  }

  doSubmit(): void {
    const title = this.submittalInreviewObj.submittal_in_review.IsCompleted
      ? "Confirm Action?"
      : "Reject Submittal?";
    let body = "";

    if (!this.submittalInreviewObj.submittal_in_review.IsCompleted || !this.submittalInreviewObj.IsQAQCCompliance) {
      body =
        "The Submittal will be sent back to the DB User. Once it's submitted, you will no longer be able to edit the submittal.";
    } else {
      body =
        "Once submitted, your task will be completed and you will no longer be able to make updates. The submittal will be distributed and the reviewers and observers will be notified.";
    }

    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";

    modalInstance.instance.title = title;

    modalInstance.instance.body = body;

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.submittalInreviewObj.submittal_in_review.IsDraft = false;
        this.submittalInreviewObj.submittal_notes.IsDraft = false;
        this.update();
      }
    });
  }

  saveDraft(): void {
    if (!this.hasChanges() || !this.IsInputValid() || !this.submittalInreviewObj.IsQAQCCompliance || !this.submittalInreviewObj.submittal_in_review.IsCompleted) return;
    
    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      this.doSaveDraft();
    }
  }

  doSaveDraft(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Save as Draft?";
    modalInstance.instance.body = "Your Submittal will save as draft.";

    modalInstance.result.then(async (result) => {
      if (result === 1) {
        this.submittalInreviewObj.submittal_in_review.IsDraft = true;
        this.submittalInreviewObj.submittal_notes.IsDraft = true;
        this.update();
      }
    });
  }

  getTime() {
    const x = 30; //minutes interval
    const times: ISelectItem[] = []; // time array
    let tt = 0; // start time
    const ap = ["AM", "PM"]; // AM-PM
    for (let i = 0; tt < 24 * 60; i++) {
      const hh = Math.floor(tt / 60);
      const mm = tt % 60;
      const time = `${
        hh === 0 || hh === 12 ? "12" : (hh % 12).toString().slice(-2)
      }:${("0" + mm).slice(-2)} ${ap[hh < 12 ? 0 : 1]}`;

      times.push({
        checked: false,
        id: time,
        name: time,
      });
      tt = tt + x;
    }
    return times;
  }

  setIsComplete(e: boolean): void {
    this.submittalInreviewObj.submittal_in_review.IsCompleted = e;
    
  }

  setIfBlubeamSessionRequired(e: boolean): void {
    this.submittalInreviewObj.IsBluebeamRequired = e;
    
  }

  setSessionName(e: string): void {
    if (e) {
      this.submittalInreviewObj.submittal_bluebeam_detail.SessionName = e;
      
    }
  }

  update() {
    this.loadingService.start();
    
    if(this.submittalInreviewObj.submittal_in_review.IsDraft !== true && !this.submittalInreviewObj.submittal_reviewer.find((r)=>{return r === this.entity.ManagerId}))
    {
      this.submittalInreviewObj.submittal_reviewer.push(this.entity.ManagerId);
    }
    if(!this.submittalInreviewObj.IsQAQCCompliance)
    {
      this.submittalInreviewObj.submittal_notes.Notes = "";
      delete this.submittalInreviewObj.submittal_duedate;
    }
    this.updateFiles();
    this.baseFunctionService.update(this.submittalInreviewObj);
  }

  updateFiles(): void {
    const oldCoverSheet = this.oldCoverSheet.map((f) => f.Guid);

    const filesToAdd = this.coverSheet.filter((f) => {
      return (
        !oldCoverSheet.includes(f.Guid) && f.Status === UploadStatus.LOADED
      );
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = this.coverSheet.map((f) => {
      return f.Guid;
    });

    const filesToRemove = oldCoverSheet.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittalInreviewObj.docSave = filesToAdd;
    this.submittalInreviewObj.docRemove = removeIds;
  }

  setBluebeamDueDate(e: Date | any): void {
    if (e.target?.value == "") {
      this.bluebeamDueDate = null;
    }

    if (_.isNil(e) || e.type == "change") return;

    this.bluebeamDueDate = new Date(e);

    if (_.isNil(this.bluebeamDueTime)) {
      this.submittalInreviewObj.submittal_bluebeam_detail.Due =
        this.bluebeamDueDate.toISOString();
      
      return;
    }
    const [hours, minutes] = this.bluebeamDueTime
      .split(":")
      .map((e) => parseInt(e));

    this.setFullDueDate(hours, minutes);
  }

  setDate(e: Date): void {
    // console.log("setDate", e);
    this.internalDueDate = new Date(e);
    this.submittalInreviewObj.submittal_duedate.InternalDueDate = new Date(e).toISOString();
    this.minDueDate = this.internalDueDate.toLocaleDateString();
    
  }

  setDueDate(e: Date): void {
    // console.log("setDate", e);
    this.dueDate = new Date(e);
    this.submittalInreviewObj.submittal_duedate.DueDate = new Date(e).toISOString();
    this.maxInternalDueDate = this.dueDate.toLocaleDateString();
    
  }

  setTime(e: any): void {
    if (e.target.value == "") {
      this.bluebeamDueTime = null;
      
      return;
    }
    const [hours, minutes] = e.target?.value.split(":");
    this.setFullDueDate(hours, minutes);
  }

  setFullDueDate(hours: number, minutes: number): void {
    const temp = _.isNil(this.bluebeamDueDate)
      ? new Date()
      : new Date(this.bluebeamDueDate);
    temp.setHours(hours);
    temp.setMinutes(minutes);
    temp.setSeconds(0);

    if (this.bluebeamDueDate) {
      this.bluebeamDueDate = temp;
    } else {
      this.bluebeamDueDate = null;
    }
    this.submittalInreviewObj.submittal_bluebeam_detail.Due = temp.toISOString();
    this.bluebeamDueTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    
  }

  getDateWithOffSet(date: string | null): string {
    if (!date) return null;
    const tempDate = new Date(date.replace("00:00:00.000Z", "12:00:00.000Z"));
    return tempDate.toLocaleDateString();
  }

  hasFilesUploading(): boolean {
    return this.coverSheet.some(
      (item) => item.Status === UploadStatus.UPLOADING,
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }

  qaqcFail(): void {
    this.submittalInreviewObj.IsQAQCCompliance = false;
  }
  getNotesLength(): number {
    return this.submittalInreviewObj.submittal_notes?.Notes ? this.submittalInreviewObj.submittal_notes.Notes.length : 0;
  }
  setNotes(e: string): void {
    this.submittalInreviewObj.submittal_notes.Notes = e;
    
  }
  reassign(): void {
    this.baseFunctionService.reassign();
  }
}
