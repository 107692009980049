import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import LoadingService from "@services/loading.service";
import LocalContractUserService from "@services/local-contractUser.service";
import RouteParams from "@shared/route-params";
import { forkJoin, Observable, of } from "rxjs";
import {
  IPSubDocumentDownload,
  IPSubItem,
  SubmittalDocumentsService,
  SubmittalService,
} from "../api-generated";
import LocalSubmittalItemService from "@services/local-submittalItem.service";
import RowItem from "@content/selectUser/table-row";
import { ISelectItem } from "@aecom/core";
import SubmittalRole from "@models/submittalRole";
import { generateUserRowItem, isLocal } from "@shared/utils";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import { map, switchMap } from "rxjs/operators";
import SubmittalStatus from "@models/submittalStatus";
import LocalKeyWordsService from "@services/local-keywords.service";
import { AuthService } from "@bbj/components";

export interface ISubmittalViewResolver {
  submittal: IPSubItem;
  documents: IPSubDocumentDownload[];
  currentUserId: string;
  role: string;
  allUsers: RowItem[];
  coordinatorsList: ISelectItem[];
  managersList: ISelectItem[];
  srManagersList: ISelectItem[];
  ddcManagersList: ISelectItem[];
  isDBAdmin: boolean;
  isDoc: boolean;
  keywords?: string[];
}

@Injectable({
  providedIn: "root",
})
export default class ViewResolver {
  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public submittalService: SubmittalService,
    public submittalDocumentService: SubmittalDocumentsService,
    public localContractUserService: LocalContractUserService,
    public localSubmittalItemService: LocalSubmittalItemService,
    public localKeyWordsService: LocalKeyWordsService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<ISubmittalViewResolver>
    | Promise<ISubmittalViewResolver>
    {
    this.loadingService.start();
    this.localSubmittalItemService.firstLoad = false;
    const currentUser = this.authService.getUserId();
    const submittalId = route.params[RouteParams.ItemId];
    const coordinatorsList: ISelectItem[] = [];
    const managersList: ISelectItem[] = [];
    const srManagersList: ISelectItem[] = [];
    const ddcManagersList: ISelectItem[] = [];

    const users = this.localContractUserService
      .getUsers([], isLocal()?[SubmittalRole.DBAdmin]:[SubmittalRole.DBAdmin, SubmittalRole.Doc_Control],)
      .map((user) => {
        const tempUser = {
          id: user.UserId,
          name: user.UserInfo.displayName ?? "",
          checked: false,
        };

        if (
          user.RolesId === SubmittalRole.Coordinator
        ) {
          coordinatorsList.push(tempUser);
        } else if (
          user.RolesId === SubmittalRole.Manager
        ) {
          managersList.push(tempUser);
        } else if (
          user.RolesId === SubmittalRole.SR_Manager
        ) {
          srManagersList.push(tempUser);
        } else if (
          user.RolesId === SubmittalRole.DDC_Manager
        ) {
          ddcManagersList.push(tempUser);
        }
        return generateUserRowItem<IContractUserWithUserInfo>(user, {
          role: user.contract_user_application_role[0].ApplicationRoleId,
        });
      });
      
    const userRole =
      this.localContractUserService.getUserById(currentUser).RolesId || "User";
    const isDoc = userRole === SubmittalRole.Doc_Control;
    const isDBAdmin = isLocal()?(isDoc||userRole === SubmittalRole.DBAdmin):userRole === SubmittalRole.DBAdmin;
    
    return forkJoin([
      this.submittalService.getSubmittalItem(submittalId, true),
      this.submittalDocumentService.getAllSubmittalDocuments(submittalId),
    ]).pipe(
      switchMap(([submittal, documents])=>{
        return forkJoin({
          submittal:of(submittal), 
          documents:of(documents),
          currentUserId: of(currentUser),
          role: of(userRole),
          allUsers: of(users),
          coordinatorsList: of(coordinatorsList),
          managersList: of(managersList),
          srManagersList: of(srManagersList),
          ddcManagersList: of(ddcManagersList),
          isDBAdmin: of(isDBAdmin),
          isDoc: of(isDoc),
          keywords: submittal.CoordinatorId === currentUser && submittal.Status===SubmittalStatus.Compiling
            ? of(this.localKeyWordsService.getItems().map((keywords)=> keywords.Name))
            : of(undefined),
        });
      })
    );
  }
}
