import { FileModel, ISelectItem, ModalContainerService, NotificationComponent, NotificationType, UploadStatus } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "@bbj/components";
import failureReason from "@models/failureReasons";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalRejectDetails from "@models/submittalRejectDetail";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import { failureFormName, getVersionString } from "@shared/utils";
import _ from "lodash";
import { IPSubDocumentUploadReturn, IPSubItem, SubmittalDocumentsService } from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "rejectForm",
  templateUrl: "./rejectForm.component.html",
  styleUrls: ["./rejectForm.component.scss"],
})
export default class RejectFormComponent implements OnInit {
  @Input() submittalRejectDetail: SubmittalRejectDetails;
  @Output() submittalRejectDetailChange = new EventEmitter<SubmittalRejectDetails>();
  @Input() coverSheet: FileModel[];
  @Output() coverSheetChange = new EventEmitter<FileModel[]>();
  @Input() removedFileIds: string[];
  @Output() removedFileIdsChange = new EventEmitter<string[]>();
  @Input() entity: IPSubItem;
  @Input() tempId: string;
  @Input() canIssueSubmittal: boolean;

  failureReasonsList = [...failureReason];
  
  showFailureForm = false;

  failureForm: SubmittalRejectDetails;

  selectedReason: string[] = [];

  currentUser: string;

  constructor(
    public authService: AuthService,
    public activeModal: ModalContainerService,
    public submittalDocumentService: SubmittalDocumentsService,
    public fileManagementService: FileManagementService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getUserId();
    this.failureReasonsList.forEach((item)=>{
      item.checked = false;
    })
    if(this.submittalRejectDetail.Reason)
    {
      this.selectedReason = this.submittalRejectDetail.Reason.split(',');
    }
    if(this.coverSheet.find((cs)=>{return cs.Name===failureFormName(this.entity.SubmittalNumber)}))
    {
      this.failureForm = _.cloneDeep(this.submittalRejectDetail);
    }

  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  setFailureReason(e: ISelectItem[]): void {
    console.log(e);
    if (e && Array.isArray(e) && e.length > 0) {
      const reasons = e.map((r)=>{return r.id}).join(',');
      if(!this.failureForm) {
        this.submittalRejectDetail.Reason = reasons;
        this.submittalRejectDetailChange.emit(this.submittalRejectDetail);
      } else if(this.failureForm.Reason !== reasons) {
        this.failureReasonsList.forEach((reason)=>{
          reason.checked = this.failureForm.Reason?.includes(reason.id);
        });
        this.failureFormAlert();
      }
    }
  }

  failureFormAlert() {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Error";
    modalInstance.instance.body =
      "The selection was changed after generating the form. Please delete the form and generate again with the desired selection.";
    modalInstance.result;
  }

  generateFailureForm() {
    if(this.submittalRejectDetail.Reason && this.submittalRejectDetail.Reason.trim() !== '')
    {
      this.failureForm = _.cloneDeep(this.submittalRejectDetail);
      this.showFailureForm = true;
    } else {
      this.NoFailureFormAlert();
    }
    
  }

  NoFailureFormAlert() {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Error";
    modalInstance.instance.body =
      "Please select failure reason before generating the QA/QC failure form.";
    modalInstance.result;
  }

  closeFailureForm(e: File | null) {
    console.log(e);
    if(e)
    {
      const fileModule = new FileModel('', e.name, '', '', 'uploading', 0, e);
      this.importFile([fileModule], SubmittalFileType.CoverSheetStampSigned);
    } else {
      this.failureForm = null;
    }
    this.showFailureForm = false;
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            getVersionString(this.entity.SubmittalNumber),
            this.entity.Status,
            this.entity.Guid,
            this.submittalRejectDetail.Guid ?? this.tempId,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item, type);
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    this.updateFileList(e, type, true);
    if(e.Name === failureFormName(this.entity.SubmittalNumber) && type === SubmittalFileType.CoverSheetStampSigned)
    {
      this.failureForm = null;
    }
  }

  updateFileList(item: FileModel, type: string, isRemove = false): void {
    let index = -1;

    switch (type) {
      case SubmittalFileType.CoverSheetStampSigned:
        index = this.coverSheet.findIndex((file) => {
          return file.Name === item.Name;
        });

        if (isRemove) {
          if (index !== -1) {
            this.coverSheet.splice(index, 1);
          }
        } else {
          if (index === -1) {
            this.coverSheet.push(item);
            this.coverSheet = this.coverSheet.slice();
          }
        }
        this.coverSheetChange.emit(this.coverSheet);
        break;
    }

    if (isRemove) {
      this.removedFileIds.push(item.Guid);
    }
  }
}


