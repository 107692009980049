import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@bbj/components";

@Component({
  selector: "app-signout",
  templateUrl: "./signout.component.html",
  styleUrls: ["./signout.component.scss"]
})
export class SignoutComponent implements OnInit {
  constructor(public authService: AuthService, public router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.activeRoute.routeConfig.path === "logout") {
      this.authService.logout();
    }
  }

  login(): void {
    this.router.navigate(["/"]);
  }
}
