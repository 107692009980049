import { canRevise } from "@shared/utils";
import { v4 as uuidv4 } from 'uuid';
import { IPSubItem } from "../api-generated";
import { ISubCreateStep } from "../api-generated/model/iSubCreateStep";
import SubmittalCreateBase from "./submittalCreateBase";
import SubmittalItemUpdateBase from "./submittalItemUpdateBase";
import SubmittalDueDateDetails from "./submittalDueDateDetail";

export default class SubmittalCreate
  extends SubmittalItemUpdateBase
  implements ISubCreateStep
{
  public submittal_create: SubmittalCreateBase;

  public tempId?: string;

  public submittal_duedate?: SubmittalDueDateDetails;

  constructor(contractId: string, item?: IPSubItem) {
    super(contractId, item);

    this.submittal_create = item?.submittal_create
      ? new SubmittalCreateBase(item.submittal_create[0])
      : new SubmittalCreateBase();
    
    this.submittal_duedate = new SubmittalDueDateDetails();

    if (!item || canRevise(item.Status)) {
      this.tempId = uuidv4();
    }
  }
}
