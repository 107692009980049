import LocalUserService from "@services/local-user.service";
import {
  IPSubListItem,
  IPRFISpecification,
  SubmittalDocumentType,
  SubmittalType,
  IPRFIContractRequirement,
  Phase,
} from "../api-generated";
import LocalApplicationRoleService from "@services/local-application_role.service";
import SubmittalListRowItem from "./submittalListViewModel";

//Submittal view model
export default class SubmittalListRowItemNested extends SubmittalListRowItem {
  public items: SubmittalListRowItem[];
  public collapsed: boolean;

  constructor(
    localApplicationRoleService: LocalApplicationRoleService,
    contractId: string,
    currentuserId: string,
    subItem: IPSubListItem,
    userRole: string,
    isOld: boolean,
    docType?: SubmittalDocumentType,
    spec?: IPRFISpecification,
    subtype?: SubmittalType,
    contractRequirement?: IPRFIContractRequirement,
    phase?: Phase,
    localUserService?: LocalUserService,
  ) {
    super(localApplicationRoleService,
      contractId,
      currentuserId,
      subItem,
      userRole,
      isOld,
      docType,
      spec,
      subtype,
      contractRequirement,
      phase,
      localUserService);
    this.collapsed = true;
    this.items = [];
  }
}
