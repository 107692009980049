import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
  UploadStatus,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import tableHeader from "@content/selectUser/table-header";
import TableHeaderCol from "@content/selectUser/table-header-col";
import RowItem from "@content/selectUser/table-row";
import { ComponentCanDeactivate } from "@guards/pending-changes.guard";
import ColumnType from "@models/columnType";
import FileModelWithVersion from "@models/fileModelWithVersion";
import SubmittalBluebeamStep from "@models/submittalBluebeamStep";
import SubmittalDocumentUpload from "@models/submittalDocumentUpload";
import SubmittalFileType from "@models/submittalFileType";
import SubmittalRecallApproval from "@models/submittalRecallApproval";
import SubmittalRole from "@models/submittalRole";
import SubmittalStatus from "@models/submittalStatus";
import { ISubmittalViewResolver } from "@resolvers/view.resolver";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import FileManagementService from "@services/fileManagement.service";
import LoadingService from "@services/loading.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  getDate,
  getDisplayedTime,
  getFilesByType,
  getFormattedTime,
  getTime,
  getVersionString,
  isFileNameInvalid,
  isValidBluebeamSessionId,
  prepareMutiLineText,
} from "@shared/utils";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  EmailService,
  IPSubDocumentDownload,
  IPSubDocumentUploadReturn,
  IPSubItem,
  IUser,
  SubmittalDocumentsService,
  SubmittalRecall,
} from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-bluebeam",
  templateUrl: "./bluebeam.component.html",
  styleUrls: ["./bluebeam.component.scss"],
})
export default class BluebeamComponent implements OnInit, ComponentCanDeactivate {
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  allUserTableRows: RowItem[] = [];
  currentUser: string;
  submittal: SubmittalBluebeamStep;
  oldSubmittal: SubmittalBluebeamStep;
  entity: IPSubItem;

  reviewerWindowTitle = "Assign Reviewer";
  reviewerTableData: RowItem[] = [];
  reviewerTableRows: RowItem[] = [];
  
  watcherWindowTitle = "Assign Watcher";
  watcherTableData: RowItem[] = [];
  watcherTableRows: RowItem[] = [];
  
  selectUserWindowTitle: string;
  showSelectUser = false;
  defaultTab = "bluebeam";
  enableSaveDraft = false;
  saved = false;
  canIssueSubmittal = false;
  canSaveSubmittal = false;
  tableRows: RowItem[] = [];
  SubmittalDocuments: unknown[];
  bluebeamDueDate: Date | null;
  bluebeamDueTime: string;
  contractId: string;
  tableHeader: TableHeaderCol[] = tableHeader;
  activeSave = false;
  columnType = ColumnType;

  comments: FileModel[];
  newComments: FileModel[] = [];
  oldComments: string[] = [];

  revisedFiles: FileModelWithVersion[];
  dispositionFiles: FileModelWithVersion[];
  compiledFiles: FileModelWithVersion[];
  commentsNameValid = true;

  isDocControl = false;

  isRequest = false;

  isRecalled = false;
  isRejected = false;
  recalledComments: SubmittalRecall;

  recallApproveItem: SubmittalRecallApproval;

  // rejectedComents: IPSubRejectDetail;
  commentsFromCoordinator: string[] = [];
  today: Date;

  docControlUser: IUser;

  private removedFileIds: string[] = [];

  allDocuments: IPSubDocumentDownload[];
  docs: IPSubDocumentDownload[];
  requireBluebeam = true;
  isOld = false;
  bluebeamName: string;
  bludbeamEmail: string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public submittalDocumentService: SubmittalDocumentsService,
    public localContractUserService: LocalContractUserService,
    public fileManagementService: FileManagementService,
    public emailService: EmailService,
    public baseFunctionService: BaseFunctionService,
    public localContractService: LocalContractService,
  ) {}

  ngOnInit(): void {
    const data: ISubmittalViewResolver =
      this.activatedRoute.snapshot.data.submittalData;
    
    this.today = new Date();
    this.today.setDate(new Date().getDate() + 1);

    this.currentUser = data.currentUserId;
    this.contractId = data.submittal.ContractId;

    this.entity = data.submittal;
    this.requireBluebeam = this.entity.IsBluebeamRequired;
    this.oldSubmittal = new SubmittalBluebeamStep(
      this.contractId,
      this.entity,
    );
    console.log(this.oldSubmittal);
    
    this.allUserTableRows = data.allUsers;

    this.reviewerTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubmittal.submittal_reviewer.includes(u.id);
    });

    this.watcherTableData = this.allUserTableRows.filter((u) => {
      return this.oldSubmittal.submittal_watcher.includes(u.id);
    });

    this.allDocuments = data.documents;
    this.docs = data.documents;

    this.commentsFromCoordinator =
      data.submittal.submittal_bluebeam_request?.map(
        (comment) => prepareMutiLineText(comment.Comments),
      );

    this.comments = getFilesByType(
      data.documents.filter((item) => {
        return (
          item.Status === SubmittalStatus.Bluebeam_Request &&
          item.FileType === SubmittalFileType.Comments
        );
      }),
    );

    this.compiledFiles = getFilesByType(
      data.documents.filter((item) => {
        return item.FileType === SubmittalFileType.CoverSheetStampSigned;
      }),
    );

    this.revisedFiles = getFilesByType(
      data.documents.filter((item) => {
        return item.FileType === SubmittalFileType.CoverSheetStampSigned;
      }),
    );

    this.dispositionFiles = getFilesByType(
      data.documents.filter((item) => {
        return item.FileType === SubmittalFileType.CoverSheetStampSigned;
      }),
    );
    const role = this.localContractUserService.currentUserContractRole;
    this.isDocControl = role === SubmittalRole.Doc_Control || this.currentUser === this.entity.CoordinatorId;
    const contract = this.localContractService.currentContract;
    this.bludbeamEmail = contract.BluebeamManagerEmail;
    this.bluebeamName = contract.BluebeamManagerName;

    if (this.entity.Status == SubmittalStatus.Bluebeam_Request) {
      this.isRequest = true;
    }

    this.isRecalled =
      this.entity.IsRecalled ||
      this.entity.Status === SubmittalStatus.Recalled;
    this.isRejected = this.entity.Status === SubmittalStatus.QAQC_Fail;

    if (!this.oldSubmittal.submittal_bluebeam_detail.Guid) {
      this.bluebeamDueTime = null;
    } else {
      this.bluebeamDueDate = new Date(this.oldSubmittal.submittal_bluebeam_detail.Due);
      this.bluebeamDueTime = getFormattedTime(this.oldSubmittal.submittal_bluebeam_detail.Due);
    }

    if (this.isRecalled && this.entity.submittal_recall.length > 0) {
      this.recalledComments = this.entity.submittal_recall.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() -
          new Date(a.DateUpdated).getTime(),
      )[0];
      this.recalledComments.Notes = prepareMutiLineText(this.recalledComments.Notes);
      this.recallApproveItem = new SubmittalRecallApproval(this.contractId, this.entity, this.recalledComments);

      console.log("isRecalled: recalledComents", this.recalledComments);
    }

    this.defaultTab = this.isRecalled ? "details" : "bluebeam";

    this.docControlUser = this.localContractUserService.getUserById(
      this.entity.DocControlId,
    )?.UserInfo || this.localContractUserService.getUserById(
      this.currentUser,
    )?.UserInfo;

    this.submittal = _.cloneDeep(this.oldSubmittal);
    
    this.oldComments = [];
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const submittal = JSON.stringify(this.submittal);
    const oldSubmittal = JSON.stringify(this.oldSubmittal);

    const oldComments = JSON.stringify(this.oldComments);
    const currentComments = JSON.stringify(this.newComments.map((c) => c.Guid));

    const changes =
      oldSubmittal != submittal ||
      oldComments != currentComments;

    if (!changes) {
      this.canIssueSubmittal = false;
      this.canSaveSubmittal = false;
    }

    return changes;
  }

  IsInputValid(): boolean {
    return !this.baseFunctionService.isInputInvalid(this.submittal.submittal_bluebeam_detail.SessionId)
  }

  IsValid(): boolean {
    if (this.isRecalled) return true;

    return (
      this.isValidBluebeamSessionId(this.submittal.submittal_bluebeam_detail.SessionId) &&
      this.isFileNameValid() &&
      !_.isNil(this.bluebeamDueDate) &&
      (!this.isRequest || (this.isRequest && this.newComments.length >= 1))
    );
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  openBlueBeam(): void {
    window.open(
      `https://studio.bluebeam.com/join.html?ID=${this.submittal.submittal_bluebeam_detail.SessionId}`,
      "_blank",
    );
  }

  removeUserClick(row: RowItem, role: string): void {
    if (role === "reviewer") {
      this.reviewerTableData = this.reviewerTableData.filter(
        (reviewer) => reviewer.id !== row.id,
      );
      this.submittal.submittal_reviewer = this.reviewerTableData.map((u) => {
        return u.id;
      });
    } else {
      this.watcherTableData = this.watcherTableData.filter(
        (watcher) => watcher.id !== row.id,
      );
      this.submittal.submittal_watcher = this.watcherTableData.map((u) => {
        return u.id;
      });
    }
  }

  openWindowClick(role: string): void {
    this.activeSave = false;

    this.selectUserWindowTitle =
      role === "reviewer" ? this.reviewerWindowTitle : this.watcherWindowTitle;

    this.tableRows = this.allUserTableRows
      .filter((user) => {
        
        if (this.selectUserWindowTitle == this.reviewerWindowTitle) {
          return (
            this.reviewerTableRows.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        } else {
          return (
            this.watcherTableRows.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        }
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        if (this.selectUserWindowTitle === this.reviewerWindowTitle) {
          this.reviewerTableData = this.reviewerTableData.concat(e);
          this.submittal.submittal_reviewer = this.reviewerTableData.map(
            (u) => {
              return u.id;
            },
          );
        } else {
          this.watcherTableData = this.watcherTableData.concat(e);
          this.submittal.submittal_watcher = this.watcherTableData.map(
            (u) => {
              return u.id;
            },
          );
        }
        this.showSelectUser = false;
        
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  submit(): void {
    this.canIssueSubmittal = true;

    if (!this.IsValid() || !this.IsInputValid()) {
      return;
    } else if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else if (!this.isRecalled) {
      const title = "Update Bluebeam Session?";

      const body =
        "Bluebeam Session detail information will be sent to the Coordinator and Reviewers.";

      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";

      modalInstance.instance.title = title;

      modalInstance.instance.body = body;

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.update();
        }
      });
    } else if (this.isRecalled) {
      this.update();
    }
  }

  update() {
    this.loadingService.start();
    this.updateFiles();
    this.baseFunctionService.update(this.isRecalled ? this.recallApproveItem : this.submittal);
  }

  updateFiles(): void {
    const filesToAdd = this.newComments.filter((f) => {
      return (
        !this.oldComments.includes(f.Guid) && f.Status === UploadStatus.LOADED
      );
    }).map((f) => {
      return f.Guid;
    });

    const fileIds = this.newComments.map((f) => {
      return f.Guid;
    });

    const filesToRemove = this.oldComments.filter((f) => {
      return !fileIds.includes(f);
    });

    const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];

    this.submittal.docSave = filesToAdd;
    this.submittal.docRemove = removeIds;
  }

  setDate(e: Date | any): void {
    if (e.target?.value == "") {
      this.bluebeamDueDate = null;
    }

    if (_.isNil(e) || e.type == "change") return;

    this.bluebeamDueDate = new Date(e);

    if (_.isNil(this.bluebeamDueTime)) {
      this.submittal.submittal_bluebeam_detail.Due = this.bluebeamDueDate.toISOString();
      
      return;
    }

    const [hours, minutes] = this.bluebeamDueTime
      .split(":")
      .map((e) => parseInt(e));

    this.setFullDueDate(hours, minutes);
  }

  setTime(e: any): void {
    if (e.target.value == "") {
      this.bluebeamDueTime = null;
      
      return;
    }

    const [hours, minutes] = e.target?.value.split(":");
    this.setFullDueDate(hours, minutes);
  }

  setFullDueDate(hours: number, minutes: number): void {
    const temp = _.isNil(this.bluebeamDueDate)
      ? new Date()
      : new Date(this.bluebeamDueDate);
    temp.setHours(hours);
    temp.setMinutes(minutes);
    temp.setSeconds(0);

    if (this.bluebeamDueDate) {
      this.bluebeamDueDate = temp;
    } else {
      this.bluebeamDueDate = null;
    }

    this.submittal.submittal_bluebeam_detail.Due = temp.toISOString();
    this.bluebeamDueTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    
  }

  setAcceptRecall(e: boolean): void {
    this.recallApproveItem.ShowRecallRejected = e;
    
  }

  setSessionId(e: any): void {
    this.submittal.submittal_bluebeam_detail.SessionId = e || null;
    
  }

  getTime(a) {
    return getTime(a);
  }

  getDisplayedTime(a) {
    return getDisplayedTime(a);
  }

  isValidBluebeamSessionId(sessionId: string): boolean {
    return isValidBluebeamSessionId(sessionId);
  }

  getDate(a) {
    return getDate(a);
  }

  importFile(e: FileModel[], type: string): void {
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new SubmittalDocumentUpload(
            item.Name,
            this.currentUser,
            type,
            getVersionString(this.submittal.SubmittalNumber),
            this.submittal.Status,
            this.submittal.Guid,
          );

          await this.submittalDocumentService
            .createSubmittalDocument(fileUploaded)
            .toPromise()
            .then((r: IPSubDocumentUploadReturn) => {
              item.Guid = r.Guid;
              const index = this.newComments.findIndex((file) => {
                return file.Name === item.Name;
              });

              if (index === -1) {
                this.newComments.push(item);
                this.newComments = this.newComments.slice();
              }

              this.isFileNameValid();
              
              if (environment.fileService === "azure") {
                AzureBlobService.uploadFile(r.URL, item);
              } else {
                this.fileManagementService.uploadFileToS3(r.URL, item);
              }
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item, type);
        }
      });
    }
  }

  deleteFile(e: FileModel, type: string): void {
    const index = this.newComments.findIndex((file) => {
      return file.Name === e.Name;
    });

    if (index !== -1) {
      this.newComments.splice(index, 1);
    }

    this.removedFileIds.push(e.Guid);
    this.isFileNameValid();

    
  }

  get submittalFileType() {
    return SubmittalFileType;
  }

  isFileNameValid(): boolean {
    this.commentsNameValid = true;

    this.newComments.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.commentsNameValid = false;
      }
    });

    return this.commentsNameValid;
  }

  hasFilesUploading(): boolean {
    return this.newComments.some(
      (item) => item.Status === UploadStatus.UPLOADING,
    );
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }
}
